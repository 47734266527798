import { Typography, TypographyProps, useMediaQuery } from "@mui/material";
import React from "react";
interface Props extends TypographyProps {
  title: string;
  mb?: number;
  required?: boolean;
}
const TextFieldLabel: React.FC<Props> = ({
  title,
  mb = 0.4,
  required = false,
  ...props
}) => {
  return (
    <Typography
      sx={{
        fontWeight: 600,
        fontSize: { xs: "12px", md: "14px" },
        color: (theme) => theme.palette.primary.main,
        opacity: 0.75,
        mb,
      }}
      {...props}
    >
      {title} {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
  );
};

export default TextFieldLabel;
