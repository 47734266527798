import { Box,styled } from "@mui/material";

const CustomSelector = (props) => {
  return (
    <StyledWrapper>
      <label className="label" htmlFor={props.id ? props.id : props.label}>
        {props.label ? props.label : "Label"}
      </label>
      <br />
      <select className="input" value={props?.value} name={props?.name} onChange={props?.handeler}>
        <option disabled value="select">
          Select
        </option>
        {props.values.map((value, key) => (
          <option key={key} value={`${value}`}>
            {value}
          </option>
        ))}
      </select>
    </StyledWrapper>
  );
};
export default CustomSelector;

export const YearSelector = (props) => {
  let start = 1980,
    end = 2050;
  let years = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );
  let selected = props.value ? props.value : 2023;
  return (
    <StyledWrapper>
      <label className="label" htmlFor={props.id ? props.id : props.label}>
        {props.label ? props.label : "Label"}
      </label>
      <br />
      <select
        className="input"
        onChange={(e) => props.onChange(e.target.value)}
        defaultValue={selected}
      >
        {years.map((year, index) => (
          <option key={`${index}+${props.label}`} value={`${year}`}>
            {year}
          </option>
        ))}
      </select>
    </StyledWrapper>
  );
};

export const StateSelector = (props) => {
  const state = ["Uttar Pradesh", "Delhi", "Maharastra", "Bihar"];
  return (
    <StyledWrapper>
      <label className="label" htmlFor={props.id}>
        Sate
      </label>
      <br />
      <select className="input">
        <option disabled value="select">
          Select
        </option>
        {state.map((state, key) => (
          <option key={key} value={`${state}`}>
            {state}
          </option>
        ))}
      </select>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)`
  margin-top: 10px;
  text-align: left;
  .label {
    color: #020c0a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    padding: 10px 0;
    margin: 10px 0;
    opacity: 0.75;
    outline: none;
  }
  .input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.1);
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
    letter-spacing: 0.4px;
    padding: 12px;
    margin-top: 10px;
    outline: none;
  }
`;