import {
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
  Box,
  Stack,
  Dialog,
  Pagination,
  LinearProgress,
  styled,
} from "@mui/material";
import { useMemo, useState } from "react";

import { useQuery } from "react-query";

import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import formatDate from "../../common/Formater";
import { getOrgFrame } from "../../services/frame.service";
import CommonActionFrame from "../../components/upload/CommonActionFrame";
import UpdateContentLocation from "./UploadContentLocation";
import CommonTable1 from "../../common/CommanTable";
import FrameForm from "../../components/upload/forms/FrameForm";

// Style for PopUp Model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  backgroundColor: "#fff",
  boxShadow: 24,
  borderRadius: "10px",
  width: "700.638px",
};

const FrameTable = () => {
  // Apply Filter
  const [state, setState] = useState({
    uploadContentDialog: false,
    viewFramePic: "",
    viewContentDialog: false,
    data: {},
    contendData: {},
    page: 1,
  });

  //Model Opening States Handeling
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, index) => index + 1,
        id: "rowIndex", // This is a unique ID (required)
        Cell: ({ cell }) => {
          return (
            <div>
              {cell.row.index + 1 > 9
                ? cell.row.index + 1
                : `0${cell.row.index + 1}`}
            </div>
          );
        },
        size: 10,
      },
      {
        accessorKey: "frameCode",
        header: "Frame Code",
        Cell: ({ cell, index }) => (
          <>
            <Stack direction={"row"} spacing={1}>
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                onClick={() => {
                  handleViewContentData(cell?.row?.original);
                }}
              >
                {cell?.row?.original?.frameCode ?? "--"}
              </Box>
            </Stack>
          </>
        ),
        size: 100,
      },
      {
        accessorKey: "themeColor",
        header: "Theme Color",
        size: 100,
      },
      {
        header: "Size",
        size: 50,
        Cell: ({ cell, index }) => (
          <>
            {cell?.row?.original?.width}x{cell?.row?.original?.height}
          </>
        ),
      },
      {
        accessorKey: "sizeFor", //normal accessorKey
        header: "Size For",
        size: 100,
      },
      {
        accessorKey: "tags",
        header: "Tags",
        size: 150,
        Cell: ({ cell, index }) => (
          <>
            {cell?.row?.original?.tags.map((tag, i) => (
              <span
                style={{
                  color: "white",
                  backgroundColor: "black",
                  borderRadius: "4px",
                  marginLeft: "3px",
                  padding: "4px 7px",
                  textDecoration: "capitalize",
                }}
                key={i}
              >
                {tag}{" "}
              </span>
            ))}
          </>
        ),
      },
      {
        accessorKey: "createdOn",
        header: "Account Created",
        size: 150,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        header: "Action",
        size: 100,
        Cell: ({ cell, index }) => (
          <>
            <Stack direction={"row"} spacing={1}>
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                onClick={() => {
                  handleContentData(cell?.row?.original);
                }}
              >
                <Edit />
              </Box>

              <CommonActionFrame
                refresh={() => getOrgFrame_refetch()}
                event={cell?.row?.original}
              />
            </Stack>
          </>
        ),
      },
    ],
    []
  );

  const handleViewContentData = (data) => {
    setState({
      ...state,
      viewContentDialog: true,
      viewFramePic: data?.framePic,
    });
  };

  // getting Frame data tables
  const {
    refetch: getOrgFrame_refetch,
    isLoading: getOrgFrameLoading,
    isFetching: getOrgFrameFetching,
  } = useQuery({
    queryKey: ["getOrgFrame", state.page],
    queryFn: () => getOrgFrame({ page: state.page }),
    onSuccess: (success) => {
      setState((prev) => ({ ...prev, data: success?.data }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const HandleCloseForLocation = () => {
    setState((prev) => ({ ...prev, uploadContentDialog: false }));
  };
  const handleContentData = (data) => {
    setState((prev) => ({
      ...prev,
      contendData: data,
      uploadContentDialog: true,
    }));
  };

  return (
    <>
      {/* view Dialog */}
      <Dialog
        open={state.viewContentDialog}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            viewContentDialog: false,
          }))
        }
        PaperProps={{
          sx: {
            width: "300px",
            height: "40vh",
          },
        }}
      >
        <img
          src={state?.viewFramePic}
          alt="frameImage"
          style={{
            position: "static",
            height: "300px",
            width: "300px",
          }}
        />
      </Dialog>
      {/* Add Frame Dialog */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              py: 1,
            }}
          >
            <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
              Add Frame
            </Typography>
            <IconButton sx={{ p: 0, margin: 0 }} onClick={handleClose}>
              <img alt="close" src="/close_filter.png" />
            </IconButton>
          </Stack>
          <Divider />
          {/* fORM IS ADDED HERE */}
          <FrameForm
            refetch={getOrgFrame_refetch}
            handleClose={() => setOpen(false)}
          />
        </Box>
      </Modal>
      <UpdateContentLocation
        refetch={getOrgFrame_refetch}
        open={state?.uploadContentDialog}
        handleClose={HandleCloseForLocation}
        data={state?.contendData}
      />
      <StyledWrapper>
        <Stack
          direction={"row"}
          sx={{ justifyContent: "flex-end", padding: "20px 0" }}
          spacing={2}
        >
          <Button
            variant="contained"
            className="button-add-frame"
            onClick={handleOpen}
          >
            + Add Farme
          </Button>
        </Stack>
        {(getOrgFrameLoading || getOrgFrameFetching) && <LinearProgress />}
        {/* Table data*/}
        <CommonTable1
          data={state?.data?.list}
          columns={columns}
          maxHeight="53vh"
        />
        <Stack direction={"row"} justifyContent={"flex-end"} mt={0.5}>
          <Pagination
            page={state.page}
            count={state?.data?.totalPages}
            shape="rounded"
            onChange={(e, value) =>
              setState((prev) => ({ ...prev, page: value }))
            }
          />
        </Stack>
      </StyledWrapper>
    </>
  );
};
export default FrameTable;

//  Defining Adding Frame Form

const StyledWrapper = styled(Box)`
  .over-view {
    width: 100.35px;
    border-radius: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.3);
    background: #fff;
  }
  .doc-type {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
  }
  .active .over-view {
    background-color: #5bd5b9;
  }
  .active .doc-type {
    color: #fff;
  }
  a {
    text-decoration: none;
  }
  .button-filter {
    width: 122.823px;
    height: 36.062px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #1a5346;
    text-transform: capitalize;
  }
  .button-add-frame {
    text-transform: capitalize;
    width: 173.371px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.05);
    background: #1a5346;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .model-upload-button {
    width: 123.091px;
    height: 122.035px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(21, 70, 58, 0.2);
    background: rgba(90, 212, 183, 0.1);
  }
`;
