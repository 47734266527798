import React, { useState } from "react";
import {
  Checkbox,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  CustomSelectBox,
  CustomTextField,
} from "../../common/globalStyledComponent";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { SketchPicker } from "react-color";
const LocationName = ({ item, formik, openedCard, setOpenedCard }) => {
  const [color, setColor] = useState({
    ColorPickerOpen: false,
    color: "#fff",
  });
  const handleClick = () => {
    setColor({ ...color, ColorPickerOpen: !color.ColorPickerOpen });
  };

  const handleColorChange = (color) => {
    console.log(color, "in the handle color change");
    setColor({ ...color, color: color.hex });
    formik?.setValues((prev) => ({
      ...prev,
      fontColor: color?.hex,
    }));
  };

  // const [showDropDown, setShowDropDown] = React.useState(false);
  const handleChangeCheckbox = (e) => {
    formik.setValues((prev) => ({ ...prev, show: !prev.show }));
    setOpenedCard(null);
  };
  const handleDropDown = () => {
    if (formik.values.show) {
      if (openedCard === item) {
        setOpenedCard(null);
      } else {
        setOpenedCard(item);
      }
    }
  };

  return (
    <>
      <Stack direction={"row"} alignItems={"flex-start"}>
        <Checkbox
          checked={formik.values.show}
          onChange={handleChangeCheckbox}
        />
        <Stack
          sx={{
            border: 1,
            borderRadius: 2,
            borderColor: "primary.rnPrimary",
            boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
            justifyContent: "space-between",
            backgroundColor: "#00CC8C10",
            padding: 0.5,
            mb: 2,
          }}
        >
          <Stack
            direction={"row"}
            onClick={handleDropDown}
            sx={{
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "primary.main",
                cursor: "pointer",
              }}
            >
              {item}
            </Typography>
            {formik.values.show && (
              <>
                {openedCard === item ? (
                  <KeyboardArrowDown />
                ) : (
                  <KeyboardArrowRight />
                )}
              </>
            )}
          </Stack>
          <Collapse in={openedCard === item}>
            <Divider
              sx={{
                width: "101%",
                alignSelf: "center",
                backgroundColor: "primary.rnPrimary",
              }}
            />
            <Grid container spacing={2} sx={{ padding: "15px 0" }}>
              <Grid item xs={3}>
                <CustomTextField
                  fullWidth
                  label="x_axis"
                  variant="outlined"
                  size="small"
                  name="x_axis"
                  type="number"
                  value={formik?.values?.x_axis}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched.x_axis && Boolean(formik?.errors.x_axis)
                  }
                  helperText={formik?.touched.x_axis && formik?.errors.x_axis}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  fullWidth
                  label="y_axis"
                  variant="outlined"
                  size="small"
                  name="y_axis"
                  type="number"
                  value={formik?.values?.y_axis}
                  onChange={formik?.handleChange("y_axis")}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched.y_axis && Boolean(formik?.errors.y_axis)
                  }
                  helperText={formik?.touched.y_axis && formik?.errors.y_axis}
                />
              </Grid>
              <Grid item xs={4} flexDirection={"row"}>
                <Grid></Grid>

                <Stack
                  onClick={handleClick}
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    borderColor: "rgba(208, 208, 226, 1)",
                    boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
                    height: 40,
                    width: 80,
                    backgroundColor: formik?.values?.fontColor ?? "#fff",
                  }}
                />
                {color?.ColorPickerOpen && (
                  <SketchPicker
                    color={formik?.values?.color}
                    onChange={handleColorChange}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  fullWidth
                  label="fontWeight"
                  variant="outlined"
                  size="small"
                  name="fontWeight"
                  type="number"
                  value={formik?.values?.fontWeight}
                  onChange={formik?.handleChange("fontWeight")}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.fontWeight &&
                    Boolean(formik?.errors?.fontWeight)
                  }
                  helperText={
                    formik?.touched?.fontWeight && formik?.errors?.fontWeight
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  fullWidth
                  label="fontSize"
                  variant="outlined"
                  size="small"
                  name="fontSize"
                  type="number"
                  value={formik?.values?.fontSize}
                  onChange={formik?.handleChange("fontSize")}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.fontSize &&
                    Boolean(formik?.errors?.fontSize)
                  }
                  helperText={
                    formik?.touched?.fontSize && formik?.errors?.fontSize
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  fontStyle
                </Typography>

                <CustomSelectBox
                  name="fontStyle"
                  onBlur={formik?.handleBlur}
                  displayEmpty
                  fullWidth
                  value={formik?.values?.fontStyle}
                  onChange={formik?.handleChange}
                  error={
                    formik?.touched?.fontStyle && formik?.errors?.fontStyle
                  }
                >
                  <MenuItem value="">Select</MenuItem>
                  {["normal", "italic", "oblique"]?.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </CustomSelectBox>
                {formik?.touched?.fontStyle && formik?.errors?.fontStyle && (
                  <Typography sx={{ color: "red" }}>
                    {formik?.errors?.fontStyle}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Width
                </Typography>
                <CustomTextField
                  fullWidth
                  placeholder="Type width"
                  variant="outlined"
                  size="small"
                  name="width"
                  type="number"
                  value={formik?.values?.width}
                  onChange={formik?.handleChange("width")}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.width && Boolean(formik?.errors?.width)
                  }
                  helperText={formik?.touched?.width && formik?.errors?.width}
                />
                {formik?.touched?.width && formik?.errors?.width && (
                  <Typography sx={{ color: "red" }}>
                    {formik?.errors?.width}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Collapse>
        </Stack>
      </Stack>
    </>
  );
};

export default LocationName;
