import axios from "axios";
import { axiosInstance } from "./axios";
const BASE_URL = process.env.REACT_APP_BASE;
const AUTH_URL = `${BASE_URL}/auth`;

export const SendOTPonMobile = (body) => {
  return axios.post(`${AUTH_URL}/SendOTPonMobile`, body);
};

export const staffSignInWithOTP = (body) => {
  return axios.post(`${AUTH_URL}/staffSignInWithOTP`, body);
};

export const updateSelfProfile = (body) => {
  return axiosInstance.put(`${AUTH_URL}/userSelf/updateSelfProfile`, body);
};
