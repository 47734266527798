import { FilterAlt } from "@mui/icons-material";
import { IconButton, styled, Typography, useMediaQuery } from "@mui/material";
import React from "react";

interface FilterButtonProps {
  hanldeClick?: () => void;
  count?: number;
  showCount?: boolean;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  hanldeClick = () => {},
  count,
}) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <Wrapper>
      <IconButton
        onClick={hanldeClick}
        size={isMobile ? "small" : "medium"}
        className="icon_button"
      >
        <FilterAlt
          sx={{ fontSize: "40px", color: "primary.main" }}
          color="primary"
        />
        <Typography className="count_text">{count}</Typography>
      </IconButton>
    </Wrapper>
  );
};

export default FilterButton;
const Wrapper = styled("div")`
  position: relative;
  .icon_button {
    .count_text {
      position: absolute;
      padding: 0.5px 3px;
      min-height: 15px;
      min-width: 13px;
      border: 0.2px solid rgba(0, 0, 0, 0.1);
      top: 0;
      left: 33px;
      font-size: 12px;
      font-weight: 700;
      color: red;
      background-color: #fff;
      border-radius: 50%;
    }
  }
`;
