import React from "react";
import { Navigate } from "react-router-dom";
import { PATH_CONSTANTS } from "../utils/constants";
import { useSelector } from "react-redux";

const AuthProtectedRoute = ({ children }) => {
  const isLoggedIn =  useSelector((store)=> store.auth.isAuthenticated);;
  return isLoggedIn ? <Navigate to={PATH_CONSTANTS.dashboard} /> : children;
};

export default AuthProtectedRoute;
