import React from "react";
import Fallback from "../../common/Fallback";

const Political = React.lazy(() => import("./Political"));
const Leader = React.lazy(() => import("./Leader"));
const Parties = React.lazy(() => import("./Parties"));
const RegisteredPoliticalProfile = React.lazy(
  () => import("./RegisteredPoliticalProfile")
);

export const PoliticalPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Political {...props} />
  </React.Suspense>
);
export const LeaderPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Leader {...props} />
  </React.Suspense>
);
export const PartiesPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Parties {...props} />
  </React.Suspense>
);
export const RegisteredPoliticalProfilePage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <RegisteredPoliticalProfile {...props} />
  </React.Suspense>
);
