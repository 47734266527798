import { axiosInstance } from "./axios";
const BASE_URL = process.env.REACT_APP_BASE;
const PHOTO_URL = `${BASE_URL}/photo`;
export const addPhoto = (body) => {
  return axiosInstance.post(`${PHOTO_URL}/addOrgPhoto`, body);
};
export const getPhoto = (params) => {
  return axiosInstance.get(`${PHOTO_URL}/getOrgPhoto`, { params });
};
export const updatePhoto = (body) => {
  return axiosInstance.put(`${PHOTO_URL}/updateOrgPhoto`, body);
};
export const deletePhoto = (params) => {
  if (!params?.photoDocId) {
    return;
  }
  return axiosInstance.delete(
    `${PHOTO_URL}/deleteOrgPhoto/` + params.photoDocId
  );
};
