import React from "react";
import Fallback from "../../common/Fallback";

const Content = React.lazy(() => import("./Content"));
const Photo = React.lazy(() => import("./Photo"));
const Frame = React.lazy(() => import("./Frame"));
const Video = React.lazy(() => import("./Video"));
export const ContentPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Content {...props} />
  </React.Suspense>
);
export const PhotoPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Photo {...props} />
  </React.Suspense>
);
export const VideoPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Video {...props} />
  </React.Suspense>
);
export const FramePage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Frame {...props} />
  </React.Suspense>
);
