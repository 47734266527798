import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  minWidth?: string;
  title: string;
  children: React.ReactNode;
};
const CommonDialog: React.FC<Props> = ({
  open = false,
  onClose = () => {},
  minWidth = "400px",
  title = "Title",
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { minWidth: { xs: "98%", md: minWidth } },
      }}
    >
      <DialogTitle sx={{ p: "0 !important" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: 1, px: 2 }}
        >
          <Typography
            sx={{ fontSize: "16px", fontWeight: 600 }}
            color="primary"
          >
            {title}
          </Typography>
          <Box
            onClick={onClose}
            sx={{
              cursor: "pointer",
              height: "25px",
              width: "25px",
              border: "2px solid #0C2F49",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Close sx={{ color: "#0C2F49" }} />
          </Box>
        </Stack>
        <Divider sx={{ backgroundColor: "#0C2F49", mb: 1 }} />
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default CommonDialog;
