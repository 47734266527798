import {
  Box,
  styled,
  Stack,
  Paper,
  Typography,
  Button,
  Modal,
  IconButton,
  Divider,
  Tabs,
  Tab,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { YearSelector } from "../../common/CustomSelector";
import CommonInputSmall, {
  CommonTextAriaSmall,
} from "../../common/CommonInputSmall";
import CommonTable1 from "../../common/CommanTable";
import { useMutation, useQuery } from "react-query";
import {
  addOrgEvent,
  deleteEvent,
  getOrgEvent,
  updateEvent,
} from "../../services/event.service";
import formatDate from "../../common/Formater";
import { useFormik } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 0,
  borderRadius: "10px",
  width: "463.638px",
};

const Calendar = () => {
  const [data, setData] = useState([]);
  var refetchData;

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, index) => index + 1,
        id: "rowIndex",
        Cell: ({ cell }) => {
          return (
            <div>
              {cell.row.index + 1 > 9
                ? cell.row.index + 1
                : `0${cell.row.index + 1}`}
            </div>
          );
        },
        size: 50,
      },
      {
        accessorKey: "title",
        header: "Event Name",
        size: 150,
      },
      {
        accessorKey: "startDate",
        header: "Start Date",
        size: 150,
        Cell: ({ cell }) => {
          let value = cell.getValue();

          return <div>{formatDate(value)}</div>;
        },
      },
      {
        accessorKey: "endDate", //normal accessorKey
        header: "End Date",
        size: 150,
        Cell: ({ cell }) => {
          let value = cell.getValue();

          return <div>{formatDate(value)}</div>;
        },
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 150,
        Cell: ({ cell }) => {
          let value = cell.getValue();

          return <div>{formatDate(value)}</div>;
        },
      },
      {
        header: "Action",
        size: 100,
        Cell: ({ cell, index }) => (
          <CommonAction event={cell?.row?.original} refetch={refetchData} />
          // console.log("cellData",cell?.row?.original)
        ),
      },
    ],
    []
  );

  const [calendarState, setCalendarState] = useState({
    date: new Date(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    day: new Date().getDay(),
  });

  const today = new Date();

  const {
    refetch,
    isLoading: getOrgEventLoading,
    isFetching: getOrgEventFetching,
  } = useQuery({
    queryKey: ["events"],
    queryFn: () =>
      getOrgEvent({
        month: calendarState?.month + 1,
        year: calendarState?.year,
      }),
    onSuccess: (events) => {
      setData(events.data.list);
      //console.log("getting events", events);
    },
    onError: (error) => {
      setData([]);
      //console.log("errror  getting events", error);
    },
  });
  refetchData = refetch;
  // Function to change the displayed month
  const changeMonth = async (delta) => {
    const newDate = new Date(calendarState.date);
    newDate.setMonth(delta);

    await setCalendarState({
      ...calendarState,
      date: newDate,
      month: newDate.getMonth(),
      year: newDate.getFullYear(),
    });
    refetch();
  };

  // Function to change the displayed year
  const changeYear = async (delta) => {
    const newDate = new Date(calendarState.date);
    newDate.setFullYear(delta);

    setCalendarState({
      ...calendarState,
      date: newDate,
      year: newDate.getFullYear(),
    });
    refetch();
  };

  // Get the number of days in the displayed month
  const daysInMonth = new Date(
    calendarState.year,
    calendarState.month + 1,
    0
  ).getDate();

  // Calculate the day of the week for the first day of the month (0 = Sunday, 6 = Saturday)
  const firstDayOfMonth = new Date(
    calendarState.year,
    calendarState.month,
    1
  ).getDay();

  const rows1 = (start = firstDayOfMonth, days = daysInMonth) => {
    var date = 1;
    var flag = false;
    var rowList = [];

    for (let i = 0; i < 5; i++) {
      var row = [];
      for (let j = 0; j < 7; j++) {
        if (flag && date <= days) {
          row.push(date);
          date++;
        } else {
          if (j === start) {
            flag = true;
            row.push(date);
            date++;
            continue;
          }
          row.push(null);
        }
      }
      rowList.push(row);
    }
    let i = 0;
    while (date <= days) {
      rowList[0][i] = date;
      i++;
      date++;
    }
    return rowList;
  };
  const rows = rows1();
  console.log("rows", rows);

  var monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [view, setView] = useState(0);

  const colors = [
    { bgcolor: "red", color: "white" },
    { bgcolor: "yellow", color: "black" },
    { bgcolor: "blue", color: "white" },
    { bgcolor: "green", color: "white" },
  ];
  // get event by day
  function getDateEvent(date) {
    if (date.length === 0) return;
    let events = [];
    for (let i = 0; i < data.length; i++) {
      // let date_ = data[i].createdOn.split("T")[0].split("-");
      let startDate = data[i].startDate.split("T")[0].split("-");
      let endDate = data[i].endDate.split("T")[0].split("-");

      // if (parseInt(date) === parseInt(date_[2])) {
      //   events.push(data[i]);
      // console.log("Data",data[i]);
      // }

      data[i].colors = colors[Math.round(Math.random() * (colors.length - 1))];

      if (
        parseInt(date) <= parseInt(endDate[2]) &&
        parseInt(date) >= parseInt(startDate[2])
      ) {
        events.push(data[i]);
        // console.log(data[i]);
        // console.log(parseInt(date),parseInt(endDate[2]),parseInt(startDate[2]));
      }
    }
    return events;
  }

  // Adding Event Functionality
  const { mutate: addEventFun, isLoading: addOrgEventLoading } = useMutation(
    addOrgEvent,
    {
      onSuccess: (data) => {
        console.log(" adding event", data);
        refetch();
        handleClose();
      },
      onError: (error) => {
        console.log("error while adding event", error.response.data.message);
      },
    }
  );

  const initialValues = {
    startDate: new Date(),
    endDate: new Date(),
    title: "",
    description: "",
  };

  // Adding Event Form dfg
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: (formData, action) => {
      console.log("fram Valeus", formData);
      addEventFun({
        startDate: formData?.startDate,
        endDate: formData?.endDate,
        title: formData?.title,
        description: formData?.description,
      });
      action.resetForm();
    },
  });

  return (
    <>
      {(getOrgEventFetching || getOrgEventLoading) && <LinearProgress />}
      <StyledWrapper>
        <Box sx={{ p: 2, justifyContent: "center" }}>
          <Tabs
            className="view-type"
            value={view}
            onChange={(e, value) => setView(value)}
          >
            <Tab
              label="Calendar View"
              className={`view-type-name ${view === 0 ? "active" : ""}`}
              value={0}
            />
            <Tab
              label="Table View"
              className={`view-type-name ${view === 1 ? "active" : ""}`}
              value={1}
            />
          </Tabs>
        </Box>
        <Box sx={{ padding: "10px 30px" }}>
          <Stack
            direction={"row"}
            sx={{ justifyContent: "space-between", alignItems: "flex-end" }}
            spacing={2}
          >
            <Stack direction="row" spacing={2} sx={{ alignItems: "flex-end" }}>
              <YearSelector
                label="Year"
                value={calendarState.year}
                onChange={(e) => changeYear(e)}
              />
            </Stack>
            <Button
              variant="contained"
              className="button-add-frame"
              onClick={handleOpen}
            >
              + Add Event
            </Button>
          </Stack>

          <Stack
            direction={"row"}
            spacing={1}
            sx={{ justifyContent: "space-between", padding: "25px 0" }}
          >
            {monthName.map((month, index) => (
              <Button
                key={index}
                onClick={() => changeMonth(index)}
                className={calendarState.month === index ? "active" : ""}
              >
                <Paper square className="over-view">
                  <Typography className="month">{month}</Typography>
                </Paper>
              </Button>
            ))}
          </Stack>

          <Paper className="calendar" hidden={view !== 0}>
            <table width="100%" border="1">
              <caption style={{ textAlign: "left" }}>
                <Typography className="caption">
                  Calender {calendarState.year}
                </Typography>
              </caption>
              <thead>
                <tr>
                  <th>Sun</th>
                  <th>Mon</th>
                  <th>Tue</th>
                  <th>Wed</th>
                  <th>Thu</th>
                  <th>Fri</th>
                  <th>Sat</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((date_, dayIndex) => (
                      <td
                        key={dayIndex}
                        className={
                          calendarState.date.getDate() === date_ &&
                          today.getMonth() === calendarState.month
                            ? "event-box-under-row active-date"
                            : "event-box-under-row"
                        }
                      >
                        {date_ && (
                          <div className="event-list">
                            <ul
                              style={{
                                textAlign: "left",
                                padding: 0,
                                margin: 0,
                                color: "red",
                                //position: "absolute",
                                top: "-10px",
                                left: "10px",
                                listStyleType: "none",
                              }}
                            >
                              {getDateEvent(date_).map((event, i) =>
                                i < 2 ? (
                                  <li
                                    style={{
                                      backgroundColor: event?.colors?.bgcolor,
                                      color: event?.colors?.color,
                                      marginTop: "2px",
                                      borderRadius: "4px",
                                      padding: "5px",
                                      fontSize: "12px",
                                    }}
                                    key={i}
                                  >
                                    {event.title}
                                  </li>
                                ) : (
                                  <span key={i}>show more...</span>
                                )
                              )}
                            </ul>
                            <br />
                            {date_}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Paper>
          <Paper className="table" hidden={view !== 1}>
            <CommonTable1 data={data} columns={columns} />
          </Paper>
        </Box>

        {/* Add event Model Box */}
        <Modal open={open} onClose={handleClose}>
          <Box sx={style} component={"form"} onSubmit={handleSubmit}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                px: 2,
                py: 1,
              }}
            >
              <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
                Add Event
              </Typography>
              <IconButton sx={{ p: 0, margin: 0 }} onClick={handleClose}>
                <img alt="close" src="/close_filter.png" />
              </IconButton>
            </Stack>
            <Divider />
            <Stack direction={"column"} sx={{ px: 2, paddingBottom: "40px" }}>
              <Stack width="100%" direction={"row"} spacing={2}>
                <Box sx={{ width: "50%" }}>
                  <CommonInputSmall
                    label="Event Start Date"
                    type="date"
                    value={values.startDate}
                    name="startDate"
                    handeler={handleChange}
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <CommonInputSmall
                    label="Event End Date"
                    type="date"
                    value={values.endDate}
                    name="endDate"
                    handeler={handleChange}
                  />
                </Box>
              </Stack>
              <CommonInputSmall
                label="Event Name"
                type="text"
                value={values.title}
                name="title"
                handeler={handleChange}
              />
              <CommonTextAriaSmall
                label="Description"
                value={values.description}
                name="description"
                handeler={handleChange}
              />
              <Stack direction="row" justifyContent={"flex-end"}>
                {addOrgEventLoading ? (
                  <CircularProgress size={30} />
                ) : (
                  <Button
                    type="submit"
                    sx={{
                      width: "177px",
                      p: 1,
                      marginTop: "30px",
                      borderRadius: "10px",
                      background:
                        " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                      flexShrink: 0,
                    }}
                    variant="contained"
                    size="small"
                    onClick={handleOpen}
                  >
                    Send For Approval
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Modal>
      </StyledWrapper>
    </>
  );
};

const CommonAction = ({ event, refetch }) => {
  //console.log("@calender - @Common action :Getting Event Data", event);

  const [open, setOpen] = useState({ view: false, edit: false, delete: false });

  const openModel = (name) => {
    if (name === "edit") {
      setOpen((prev) => ({ ...prev, edit: true }));
    } else if (name === "delete") {
      setOpen((prev) => ({ ...prev, delete: true }));
    } else {
      setOpen((prev) => ({ ...prev, view: true }));
    }
  };
  const closeMdedel = (name) => {
    if (name === "edit") {
      setOpen((prev) => ({ ...prev, edit: false }));
    } else if (name === "delete") {
      setOpen((prev) => ({ ...prev, delete: false }));
    } else {
      setOpen((prev) => ({ ...prev, view: false }));
    }
  };

  // Updaing Event Functionality
  const { mutate: updateEventFun } = useMutation(updateEvent, {
    onSuccess: (data) => {
      console.log("updated event succssfully event", data);
      closeMdedel("edit");
      refetch();
    },
    onError: (error) => {
      console.log("error while updating event", error.response.data.message);
    },
  });

  //deleting the event
  const { mutate: deleteEventFun } = useMutation(deleteEvent, {
    onSuccess: (data) => {
      console.log("delete event succssfully event", data);
      closeMdedel("edit");
      refetch();
    },
    onError: (error) => {
      console.log("error while delteing event", error.response.data.message);
    },
  });

  const initialValues = {
    startDate: event?.startDate,
    endDate: event?.endDate,
    title: event?.title,
    description: event?.description,
  };

  //console.log("initial value s", initialValues);

  //Updating Event Form dfg
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: (value) => {
      let body = {
        eventDocId: event?._id,
        startDate: value?.startDate,
        endDate: value?.endDate,
        title: value?.title,
        description: value?.description,
      };
      console.log(body);
      updateEventFun(body);
    },
  });

  return (
    <Stack direction="row" spacing={0.5} sx={{ m: 0 }}>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("view")}>
        <img src="./action_view.png" alt="view" />
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("edit")}>
        <img src="./action_edit.png" alt="edit" />
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("delete")}>
        <img src="./action_delete.png" alt="delete" />
      </IconButton>

      {/* View model */}
      <Modal
        open={open.view}
        onClose={() => closeMdedel("view")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledWrapper1>
          <Box sx={style} component={"form"}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                px: 2,
                py: 1,
              }}
            >
              <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
                view Event
              </Typography>
              <IconButton
                sx={{ p: 0, margin: 0 }}
                onClick={() => closeMdedel("view")}
              >
                <img alt="close" src="/close_filter.png" />
              </IconButton>
            </Stack>
            <Divider />
            <Stack direction={"column"} sx={{ px: 2, paddingBottom: "40px" }}>
              <Stack width="100%" direction={"row"} spacing={2}>
                <Box className="box-profile">
                  <Typography className="title">Start Date</Typography>
                  <Typography className="value">
                    {formatDate(event?.startDate)}
                  </Typography>
                </Box>
                <Box className="box-profile">
                  <Typography className="title">End Date</Typography>
                  <Typography className="value">
                    {formatDate(event?.endDate)}
                  </Typography>
                </Box>
              </Stack>
              <Box className="box-profile">
                <Typography className="title">Title</Typography>
                <Typography className="value">{event?.title}</Typography>
              </Box>
              <Box className="box-profile">
                <Typography className="title">Description</Typography>
                <Typography className="value">{event?.description}</Typography>
              </Box>
            </Stack>
          </Box>
        </StyledWrapper1>
      </Modal>

      {/* edit model */}
      <Modal
        open={open.edit}
        onClose={() => closeMdedel("edit")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component={"form"} onSubmit={handleSubmit}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              py: 1,
            }}
          >
            <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
              Add Event
            </Typography>
            <IconButton
              sx={{ p: 0, margin: 0 }}
              onClick={() => closeMdedel("edit")}
            >
              <img alt="close" src="/close_filter.png" />
            </IconButton>
          </Stack>
          <Divider />
          <Stack direction={"column"} sx={{ px: 2, paddingBottom: "40px" }}>
            <Stack width="100%" direction={"row"} spacing={2}>
              <Box sx={{ width: "50%" }}>
                <CommonInputSmall
                  label="Event Start Date"
                  type="date"
                  value={values?.startDate.split("T")[0]}
                  name="startDate"
                  handeler={handleChange}
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <CommonInputSmall
                  label="Event End Date"
                  type="date"
                  value={values?.endDate.split("T")[0]}
                  name="endDate"
                  handeler={handleChange}
                />
              </Box>
            </Stack>
            <CommonInputSmall
              label="Event Name"
              type="text"
              value={values.title}
              name="title"
              handeler={handleChange}
            />
            <CommonTextAriaSmall
              label="Description"
              value={values.description}
              name="description"
              handeler={handleChange}
            />
            <Stack direction="row" justifyContent={"flex-end"}>
              <Button
                type="submit"
                sx={{
                  width: "177px",
                  p: 1,
                  marginTop: "30px",
                  borderRadius: "10px",
                  background:
                    " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                  flexShrink: 0,
                }}
                variant="contained"
                size="small"
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      {/* delte model */}
      <Modal
        open={open.delete}
        onClose={() => setOpen((prev) => ({ ...prev, delete: false }))}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300, p: 3, textAlign: "center" }}>
          <Stack sx={{ justifyContent: "center", alignItem: "center", p: 3 }}>
            <Box>
              <img width="69px" src="./delete-icon.png" alt="delete" />
            </Box>
          </Stack>
          <Typography
            variant="h6"
            className="confirm-title"
            sx={{
              color: "#020c0a",
              textAlign: "center",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            Are You Sure ?
          </Typography>
          <Typography
            varient="body1"
            className="confirm-description"
            sx={{
              color: "#020C0A",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              marginTop: "15px",
              opacity: "0.5",
            }}
          >
            You want to delete this Event
          </Typography>
          <Stack
            direction="row"
            justifyContent={"center"}
            sx={{ marginTop: "30px" }}
            spacing={2}
          >
            <Button
              className="confirm-button"
              type="submit"
              sx={{
                width: "95px",
                height: "50px",
                p: 1,
                marginTop: "30px",
                borderRadius: "10px",
                flexShrink: 0,
              }}
              variant="outlined"
              size="small"
              onClick={() => closeMdedel("delete")}
            >
              No
            </Button>
            <Button
              className="confirm-button"
              type="submit"
              sx={{
                width: "95px",
                height: "50px",
                p: 1,
                marginTop: "30px",
                borderRadius: "10px",
                background:
                  " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                flexShrink: 0,
              }}
              variant="contained"
              size="small"
              onClick={() => {
                deleteEventFun({ eventDocId: event?._id });
                closeMdedel("delete");
              }}
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};

export default Calendar;

const StyledWrapper1 = styled(Box)`
  .box-profile {
    margin-top: 15px;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.1);
    background: rgba(93, 215, 186, 0.05);
    backgroundcolor: red;
    padding: 10px;
  }
  .title {
    color: #555;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .value {
    margin-top: 5px;
    width: 184px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    color: #555;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;

const StyledWrapper = styled(Box)`
  .view-type {
    display: flex;
    justify-content: space-around;
    width: 309px;
    height: 50px;
    padding: 0 20px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(19, 115, 229, 0.2);
    background: rgba(39, 137, 253, 0.05);
  }

  .view-type-name {
    color: #0c2f49;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
    margin-left: 20px;
  }
  .active {
    color: #0c2f49;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    opacity: 1;
  }

  button {
    margin: 0;
    padding: 0;
    text-transform: capitalize;
  }
  .label {
    color: #020c0a;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.66px;
  }
  .label input {
    color: #020c0a;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .button-add-frame {
    width: 173.371px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.05);
    background: #1a5346;
  }
  .active .over-view {
    background: #5dd7ba;
  }
  .active .month {
    color: #fff;
  }
  .over-view {
    width: 60.781px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    padding: 10px;
    text-align: center;
  }
  a {
    text-decoration: none;
  }

  .calendar {
    border: 1px solid red;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.3);
    background: #fff;
  }
  .calendar table,
  tr,
  table,
  td {
    border-collapse: collapse;
  }
  .calendar table th {
    border: 1px solid #b3b6b6;
    background: rgba(96, 217, 189, 0.1);
    padding: 10px;
    box-sizing: border-box;
    height: 41.907px;
    color: #020c0a;
  }
  .calendar table td {
    width: 159.85px;
    height: 92.76px;
    flex-shrink: 0;
    text-align: right;
    font-size: 15.633px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    vertical-align: bottom;
    padding: 5px;
    box-sizing: border-box;
  }
  .caption {
    color: #020c0a;
    padding: 15px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20.099px; /* 100.494% */
    letter-spacing: 0.4px;
  }
  .today {
    color: #fff;
    width: 150px;
  }
  .active-date {
    background: #5dd7ba;
    color: #fff;
  }
  .event-box-under-row {
    position: relative;
    font-size: 12px;
  }
  .event-list {
    padding: 5px;
    box-sizing: border-box;
  }

  .event-list ul {
    font-size: 10px;
  }
  .event-list span {
    color: blue;
    text-decoration: underline;
  }
  .event-box-under-row:hover {
    background-color: #3bb;
    position: relative;
  }
`;
