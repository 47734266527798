import { Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import FilterButton from "./FilterButton";
type Props = {
  children: ReactNode;
  onClick: () => void;
  count: number;
};
const AppliedFiltersWrapper: React.FC<Props> = ({
  children,
  onClick,
  count = 0,
}) => {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <FilterButton hanldeClick={onClick} count={count} />
      <Stack
        sx={{ border: "1px dashed #999", p: "4px 5px", cursor: "pointer" }}
        direction={"row"}
        gap={{ xs: 0.5, md: 2 }}
        onClick={onClick}
      >
        <Typography
          fontWeight={600}
          sx={{ fontSize: { xs: "8px", md: "12px" } }}
        >
          Applied Filters :-{" "}
        </Typography>
        <Stack
          direction="row"
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={{ xs: 0.5, md: 1 }}
        >
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AppliedFiltersWrapper;
