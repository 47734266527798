import { Box, Container, Typography, styled } from "@mui/material";
const RefundPolicy = () => {
  return (
    <Container maxWidth="md">
      <Title my={2}>Refund Policies and Administration</Title>
      <Title my={2}>Trial Period</Title>
      <Text>
        After paying Registration charge, you will have 1 month as trial period,
        within this this trial period if you are not satisfying with product or
        service, you can send a mail to{" "}
        <a href="mailto: info@ekalsutra.com">info@ekalsutra.com</a> to stop the
        service and you will not need to pay any service charge, if have paid
        any service charge, whole amount will be refunded to account number by
        NEFT provide by your Institute in mail or from your admin mail Id. But
        in any case registration charge will not be refunded to customer.
      </Text>
      <Title my={2}>Cancellation Period:</Title>
      <Text>
        Post And Share charges two type of charge, <br />{" "}
        <span style={{ fontWeight: 600 }}>1. Registration Charge :</span> It is
        paid for supporting School / Institute to fill data in ERP and Trainging
        Staff for using ERP <br />{" "}
        <span style={{ fontWeight: 600 }}>2. Service charge :</span> It is paid
        based on Student Strength for availing Service of Post And Share, that
        will be refunded to customer if customer is not satisfying with service
        of Post And Share, it paid in advance on Pre-paid policy, so you have to
        pay 3 month advance payment, if you not satisfying with service of Post
        And Share then you have to mail at{" "}
        <a href="mailto: info@ekalsutra.com">info@ekalsutra.com</a> for stopping
        service, from that day you have not to pay, and if you have paid any
        advance amount, that will be refunded to customer&apos;s account.
      </Text>
      <Title my={2}>Conditions for Refund:</Title>
      <Title my={2}>No-Refund Policy:</Title>
      <Text>
        For using any service of of Post And Share, you must have to pay
        Registration Charg, without paying Registration Charge , Post And Share
        dose not give permission to to use any service service of Post And
        Share, Registration Charge is not refundable at any cost.
      </Text>
      <Title my={2}>Communication and Support:</Title>
      <Text>
        In case of any technical customer support, either you should send mail
        to <a href="mailto: support@ekalsutra.com">support@ekalsutra.com</a> or
        contact directly over the call{" "}
        <a href="tel: +91 9792669957">+91 97926 69957</a> if you have any
        questions regarding sales and price you should send mail to{" "}
        <a href="mailto: info@ekalsutra.com">info@ekalsutra.com</a> or you have
        to call <a href="tel: +91 83186 67477">+91 83186 67477</a>
      </Text>
      <Title my={2}>Partial Refunds:</Title>
      <Box px={1} mt={1}>
        <Title mb={1} mt={2}>
          Refund Process
        </Title>
        <Text my={1}>
          In order to initiate a refund request, Post And Share instructs
          Consumers to contact the Organizer directly. If no contact information
          is listed, Post And Share instructs the Consumer to use the contact
          the organizer button on the event page or such other information
          posted on the event page. Organizer agrees to administer its refund
          policy in accordance with the terms set forth on the applicable event
          page and the minimum requirements set forth in Section 1.1 above. When
          a Consumer requests a refund and the Organizer does not respond by
          either refunding or denying the request after five (5) days, Post And
          Share may, in its sole discretion, execute the refund on behalf of the
          Organizer and collect such sums directly from the Organizer.
        </Text>
        <Title mb={1} mt={2}>
          Post And Share Review
        </Title>
        <Text my={1}>
          In the event that Organizer fails to honor a refund that a Consumer
          believes is due under the applicable refund policy and/or the minimum
          requirements set forth above or deliver its promise to make good in
          connection with a credit or other accommodation issued to the
          Consumer, that Consumer may request that Post And Share initiate a
          refund by contacting us. Post And Share will review the facts and
          circumstances and determine at its sole discretion whether or not a
          refund is due in accordance with the applicable refund policy and the
          minimum requirements set forth above. Post And Share will endeavor to
          complete its review within thirty (30) days of being contacted by the
          Consumer. Consumers should note that if an Organizer has selected a
          facilitated payment method such as NEFT for an event, Post And Share
          will not have control of the funds and Post And Share will need the
          provider of the facilitated payment method to cooperate in order to
          obtain a refund, which may not happen. All determinations by Post And
          Share with respect to these Refund Policy Requirements, including
          without limitation the orders to be refunded and the size of any
          refund, shall be final and binding on both Organizer and Consumer.
        </Text>
        <Title mb={1} mt={2}>
          No Insurance or Guarantee
        </Title>
        <Text my={1}>
          Post And Share operates as a service provider company and does not
          engage in the manufacturing of hardware items. In instances where
          customers seek integration of external machines or devices with Post
          And Share&apos;s ERP system, capable of communication over a server,
          the entire responsibility for third-party devices lies with the
          customer. If a device encounters issues or fails to communicate
          effectively with Post And Share&apos;s ERP, Post And Share bears no
          responsibility for the device. The onus solely rests on the third
          party and the customer to address any concerns related to the
          functionality of the device. It is strongly recommended that customers
          obtain appropriate insurance coverage for such devices.{" "}
        </Text>
        <Title mb={1} mt={2}>
          Conditions for Return:
        </Title>
        <Text my={1}>
          There is no Return Policy for using of any service of Post And Share.
          <br />
          <br />
          Post And Share is a service provider Company, do not manufacture any
          hardware items, if customer requesting to integrate any machine or
          device with Post And Share&apos;s ERP which can commuicate over
          server, the whole responsibility of third party devices to customer,
          If device is not working or communicating proper with Post And Share
          ERP, Post And Share is not responsible for that device, only third
          party and customer is responsible for that device and it is advice to
          customer to take proper Insurance for that devices .
          <br />
          In the event that an institute purchases any device or hardware
          through Post And Share, Post And Share will charge a commission and
          provide it to the institute. However, in cases of breakdown, failure,
          or improper functioning of the device, only the manufacturer is
          responsible for maintenance and has the authority to replace or refund
          the device. Post And Share does not facilitate the return, refund, or
          maintenance of such devices.
        </Text>
      </Box>
    </Container>
  );
};

export default RefundPolicy;

const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
`;
const Text = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
`;
