import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
type Props = {
  showExcel?: boolean;
  showPdf?: boolean;
  onClickExcel: () => void;
  onClickPdf: () => void;
};
const ExportButtons: React.FC<Props> = ({
  showExcel = true,
  showPdf = -true,
  onClickExcel,
  onClickPdf,
}) => {
  return (
    <>
      <Stack direction={"row"} sx={{ alignItems: "center" }}>
        <Typography>Export :-</Typography>
        {showExcel && (
          <IconButton onClick={onClickExcel}>
            <img src="/xcel.png" alt="xcel" />
          </IconButton>
        )}
        {showPdf && (
          <IconButton onClick={onClickPdf}>
            <img src="/pdf.png" alt="xcel" />
          </IconButton>
        )}
      </Stack>
    </>
  );
};

export default ExportButtons;
