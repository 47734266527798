import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";

export const onError = (err: AxiosError<{ message: string }>) => {
  toast.error(err.response?.data.message || "Something went wrong");
};
export const onSuccess = (success: AxiosResponse) => {
  toast.error(success.data?.message || "Success");
};
export const FormatFullName = (
  firstName: string,
  middleName: string,
  lastName: string
) => {
  const nameParts = [firstName, middleName, lastName].filter(Boolean);
  return nameParts.join(" ");
};
