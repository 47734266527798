import {
  Backup,
  Ballot,
  Business,
  CalendarMonth,
  Category,
  ContactEmergency,
  Dashboard,
  Diversity1,
  InsertPhoto,
} from "@mui/icons-material";

export const POLITICAL_POSITION = [
  "Right-Wing",
  "Left-Wing",
  "Centrist",
  "Far-Right",
  "Far-Left",
  "Other",
];
export const PARTY_TYPE = ["National", "State", "District"];

export const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const PATH_CONSTANTS = {
  login: "/login",
  register: "/register",
  dashboard: "/",
  userList: "/user-list",
  staffList: "/staff-list",
  political: "/political",
  business: "/business",
  category: "/category",
  calendar: "/calendar",
  upload: "/upload",
  photo: "/photo",
  event: "/event",
  content: "/content",
};
export const COLORS_CONSTANTS = {
  primry: "",
  secondary: "",
  sidebar_gradient:
    "background: linear-gradient(198deg, #6BE1C5 -17.44%, #31B696 103.72%)",
};
export const SIZES_CONSTANTS = {
  navbarHeight: "70px",
  sidebar_width: "250px",
};
export const SIDEBAR_ITEMS = [
  {
    name: "Dashboard",
    path: PATH_CONSTANTS.dashboard,
    icon: <Dashboard className="icon" />,
  },
  {
    name: "User List",
    path: PATH_CONSTANTS.userList,
    icon: <ContactEmergency className="icon" />,
  },
  {
    name: "Staff List",
    path: PATH_CONSTANTS.staffList,
    icon: <Diversity1 className="icon" />,
  },
  {
    name: "Political",
    path: PATH_CONSTANTS.political,
    icon: <Ballot className="icon" />,
  },
  // {
  //   name: "Business",
  //   path: PATH_CONSTANTS.business,
  //   icon: <Business className="icon" />,
  // },
  {
    name: "Category",
    path: PATH_CONSTANTS.category,
    icon: <Category className="icon" />,
  },
  {
    name: "Event",
    path: PATH_CONSTANTS.event,
    icon: <CalendarMonth className="icon" />,
  },
  {
    name: "Content",
    path: PATH_CONSTANTS.content,
    icon: <CalendarMonth className="icon" />,
  },
  // {
  //   name: "Upload",
  //   path: PATH_CONSTANTS.upload,
  //   icon: <Backup className="icon" />,
  // },
  // {
  //   name: "Photo",
  //   path: PATH_CONSTANTS.photo,
  //   icon: <InsertPhoto className="icon" />,
  // },
];
export const LANGUAGE = [
  {
    label: "Hindi",
    value: "hindi",
  },
  {
    label: "English",
    value: "english",
  },
];
export const CONTENT_TEHEM_COLOR = [
  {
    label: "Green",
    value: "green",
  },
  {
    label: "Yellow",
    value: "yellow",
  },
  {
    label: "Red",
    value: "red",
  },
];
export const CONTENT_SIZE_FOR = [
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "WhatsApp",
    value: "whatsApp",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
];
export const CATEGORY_GROUP = [
  { label: "Business", value: "Business" },
  { label: "Political", value: "Political" },
  { label: "Festival", value: "Festival" },
  { label: "General", value: "General" },
];
