import {
  Button,
  Stack,
  Box,
  Pagination,
  LinearProgress,
  styled,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import formatDate from "../../common/Formater";
import CommonActionPhoto from "../../components/upload/CommonActionPhoto";
import { getPhoto } from "../../services/photo.service";
import CommonTable1 from "../../common/CommanTable";
import AddPhotoDialog from "../../dialogs/upload/AddPhotoDialog";

const PhotoTable = () => {
  const [state, setState] = useState({
    data: {},
    page: 1,
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let refresh;
  let close;

  // Form Getting States Handeling

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, index) => index + 1,
        id: "rowIndex", // This is a unique ID (required)
        Cell: ({ cell }) => {
          return (
            <div>
              {cell.row.index + 1 > 9
                ? cell.row.index + 1
                : `0${cell.row.index + 1}`}
            </div>
          );
        },
        size: 15,
      },
      {
        accessorKey: "name",
        header: "Event Name",
        size: 100,
        // Cell: ({ cell }) => {
        //   let value = cell.getValue();
        //   if(!value ) return "----";
        //   return value;
        // },
      },
      {
        accessorKey: "createdOn", //normal accessorKey
        header: "Created On",
        size: 100,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 100,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          if (!value) return "----";
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        accessorKey: "categoryTag",
        header: "Category Tag",
        size: 150,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          if (!value) return "----";
          return value;
        },
      },
      {
        accessorKey: "tags",
        header: "Tags",
        size: 150,
        Cell: ({ cell, index }) => (
          <>
            {cell?.row?.original?.tags.map((tag, i) => (
              <span
                style={{
                  color: "white",
                  backgroundColor: "black",
                  borderRadius: "4px",
                  marginLeft: "3px",
                  padding: "4px",
                }}
                key={i}
              >
                {tag}{" "}
              </span>
            ))}
          </>
        ),
      },
      {
        header: "Action",
        size: 100,
        Cell: ({ cell, index }) => (
          <CommonActionPhoto
            refresh={refresh}
            event={cell?.row?.original}
            close={close}
          />
        ),
      },
    ],
    []
  );
  const {
    refetch,
    isLoading: getPhotoLoading,
    isFetching: getPhotoFetching,
  } = useQuery({
    queryKey: ["events", state.page],
    queryFn: () => getPhoto({ page: state.page }),
    onSuccess: (success) => {
      setState((prev) => ({ ...prev, data: success?.data }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  return (
    <>
      <StyledWrapper>
        {/* add photo dialog */}
        <AddPhotoDialog open={open} onClose={handleClose} refetch={refetch} />
        {/*Main Menu*/}
        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", padding: "20px 0" }}
          spacing={2}
        >
          <Button
            variant="contained"
            className="button-add-frame"
            onClick={handleOpen}
          >
            + Add Photo
          </Button>
        </Stack>
        {(getPhotoLoading || getPhotoFetching) && <LinearProgress />}
        {/*Data Table*/}
        <CommonTable1
          data={state?.data?.list}
          columns={columns}
          maxHeight="54vh"
        />
        <Stack direction={"row"} justifyContent={"flex-end"} mt={0.5}>
          <Pagination
            page={state.page}
            count={state?.data?.totalPages}
            shape="rounded"
            onChange={(e, value) =>
              setState((prev) => ({ ...prev, page: value }))
            }
          />
        </Stack>
      </StyledWrapper>
    </>
  );
};

export default PhotoTable;

const StyledWrapper = styled(Box)`
  .over-view {
    width: 100.35px;
    border-radius: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.3);
    background: #fff;
  }
  .doc-type {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
  }
  .active .over-view {
    background-color: #5bd5b9;
  }
  .active .doc-type {
    color: #fff;
  }
  a {
    text-decoration: none;
  }
  .button-filter {
    width: 122.823px;
    height: 36.062px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #1a5346;
    text-transform: capitalize;
  }
  .button-add-frame {
    text-transform: capitalize;
    width: 173.371px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.05);
    background: #1a5346;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .model-upload-button {
    width: 123.091px;
    height: 122.035px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(21, 70, 58, 0.2);
    background: rgba(90, 212, 183, 0.1);
  }
`;
