import {
  AddSaffProfilePostProps,
  GetStaffParamsProps,
  UpdateSaffProfilePostProps,
} from "../types/staff";
import { GetUsersTableProps } from "../types/user";
import { axiosInstance } from "./axios";

const BASE_URL = process.env.REACT_APP_BASE;
const STAFF_URL = `${BASE_URL}/admin/staff`;

export const getDesignationDropdown = () => {
  return axiosInstance.get(`${STAFF_URL}/getDesignationDropdown`);
};
export const getUserProfile = () => {
  return axiosInstance.get(`${STAFF_URL}/getUserProfile`);
};

export const getStaffListByName = (params: { staffName: string }) => {
  return axiosInstance.get(`${STAFF_URL}/getStaffListByName`);
};

export const getStaffList = (params: GetStaffParamsProps) => {
  return axiosInstance.get(`${STAFF_URL}/getStaffList`, { params });
};
export const getUserList = (params: GetUsersTableProps) => {
  return axiosInstance.get(`${STAFF_URL}/getUserList`, { params });
};

export const addStaffProfile = (body: AddSaffProfilePostProps) => {
  return axiosInstance.post(`${STAFF_URL}/addStaffProfile`, body);
};

export const updateStaffProfile = (body: UpdateSaffProfilePostProps) => {
  return axiosInstance.put(`${STAFF_URL}/updateStaffProfile`, body);
};

export const deleteStaff = (id: string) => {
  return axiosInstance.delete(`${STAFF_URL}/deleteStaff/${id}`);
};
