import React from "react";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
type ControllerDatePickerProps<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  rules?: RegisterOptions<TFieldValues>;
} & Omit<DatePickerProps<Dayjs>, "value" | "onChange" | "renderInput">;

const ControllerDatePicker = <TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  ...rest
}: ControllerDatePickerProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          value={field.value || null}
          onChange={(date) => field.onChange(date)}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
              error: !!error,
              helperText: error?.message,
            },
          }}
          {...rest}
        />
      )}
    />
  );
};

export default ControllerDatePicker;
