import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import { getStaffListByName } from "../../services/staff.service";
import { FormatFullName } from "../../utils/helpers";
import { StaffListColumnProps } from "../../types/staff";
type Props = {
  handleOnClick: (res: StaffListColumnProps) => void;
};
const StaffSearch: React.FC<Props> = ({ handleOnClick }) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({
    name: "",
    page: 1,
    staffData: {
      list: [],
      pages: 1,
      page: 1,
    },
    menuOpen: false,
    staffDetail: {},
    isFocus: false,
    scrollPosition: 0,
  });
  const handleClick = (res: StaffListColumnProps) => {
    setState((prev) => ({
      ...prev,
      name: FormatFullName(res?.firstName, res?.middleName, res?.lastName),
      isFocus: false,
    }));
    handleOnClick(res);
  };
  const {
    isFetching: getStaffByNameFetching,
    isLoading: getStaffByNameLoading,
  } = useQuery({
    queryKey: ["getStaffListByName", state.name, state.page],
    queryFn: () =>
      getStaffListByName({
        staffName: state.name,
      }),
    onSuccess: (success) => {
      if (Number(state.page) !== 1) {
        setState((prev) => ({
          ...prev,
          staffData: {
            ...prev.staffData,
            ...success?.data,
            list: [...prev?.staffData?.list, ...success?.data?.list],
          },
        }));
      } else {
        setState((prev) => ({ ...prev, staffData: success?.data }));
      }
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: state.name ? true : false,
  });
  useEffect(() => {
    if (listRef.current) {
      const scrollDiv = listRef.current;
      setState((prev) => ({
        ...prev,
        scrollPosition: scrollDiv.scrollTop,
      }));
    }
  }, [state.page]);

  useEffect(() => {
    if (listRef.current && Number(state.page) > 1) {
      const scrollDiv = listRef.current;
      scrollDiv.scrollTo({
        top: state?.scrollPosition || 0,
      });
    }
  }, [state.staffData]);
  return (
    <ClickAwayListener
      onClickAway={() => setState((prev) => ({ ...prev, isFocus: false }))}
    >
      <Wrapper>
        <div className="input_box">
          <input
            onFocus={() => setState((prev) => ({ ...prev, isFocus: true }))}
            type="text"
            placeholder={`Type Staff Name`}
            value={state.name}
            onChange={(e) => {
              setState((prev: any) => ({
                ...prev,
                name: e.target.value,
                ...(state.page !== 1 && { page: 1 }),
                ...(!e.target.value && { staffData: {} }),
              }));
            }}
            className="custom_input"
          />
          {state.name && (
            <div
              onClick={() =>
                setState((prev) => ({ ...prev, name: "", studentData: {} }))
              }
            >
              <Close className="close_icon" />
            </div>
          )}
        </div>
        {/* box */}
        {state.name && state.isFocus && (
          <div className="student_box__wrapper">
            <div className="student_box" ref={listRef}>
              {(getStaffByNameFetching || getStaffByNameLoading) && (
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  sx={{ position: "absolute", left: 0, right: 0 }}
                >
                  <CircularProgress />
                </Stack>
              )}

              {state.staffData?.list?.map((item: any, ind) => (
                <Box
                  key={ind}
                  sx={{ px: 1 }}
                  className="card_wrapper"
                  onClick={() => handleClick(item)}
                >
                  <Grid container spacing={1} alignItems={"center"}>
                    <Grid
                      item
                      xs={10}
                      alignItems={"center"}
                      gap={2}
                      display={"flex"}
                      spacing={2}
                    >
                      <Grid item xs={2}>
                        <img
                          src={
                            item?.profilePic
                              ? item?.profilePic
                              : "/image-placeholder.jpeg"
                          }
                          alt="profile"
                          style={{
                            width: "60px",
                            height: "60px",
                            borderRadius: "50%",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sx={{
                          ml: 1,
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                          {FormatFullName(
                            item?.firstName,
                            item?.middleName,
                            item?.lastName
                          )}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          S/O:- {item?.fatherName}
                        </Typography>

                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          Designation:- {item?.designation ?? "--"}
                        </Typography>

                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          Gender:- {item?.gender}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider
                    sx={{
                      my: 0.5,
                      backgroundColor: "#3b3939",
                    }}
                  />
                </Box>
              ))}
              {state.staffData?.pages !== state.staffData?.page && (
                <Stack direction={"row"} justifyContent={"center"}>
                  <Typography
                    sx={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      textAlign: "center",
                      display: "inline-block",
                      mb: 1,
                    }}
                    onClick={() =>
                      setState((prev) => ({ ...prev, page: prev.page + 1 }))
                    }
                  >
                    more
                  </Typography>
                </Stack>
              )}
            </div>
          </div>
        )}
      </Wrapper>
    </ClickAwayListener>
  );
};

export default StaffSearch;
const Wrapper = styled(Box)`
  position: relative;
  transition: all 0.5s;
  .input_box {
    padding: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    border-radius: 5px;
    :hover {
      border-color: var(--primary);
    }
  }
  .close_icon {
    font-size: 10px;
    opacity: 0.6;
    background-color: #f1f1;
    cursor: pointer;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom_input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 14px;
    height: 30px;
  }

  .student_box__wrapper {
    position: absolute;
    width: 400px;
    height: 300px;
    padding-top: 4px;
    z-index: 300;
    .student_box {
      padding-top: 10px;
      height: 100%;
      width: 100%;
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
      overflow-y: auto;
    }
  }
  .card_wrapper {
    cursor: pointer;
    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;
