import {
  FormControl,
  MenuItem,
  SelectProps,
  FormHelperText,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { CustomSelectBox } from "./globalStyledComponent";
type Option = {
  label: string;
  value: string | number;
};

type ControllerSelectProps<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  options: Option[];
  rules?: RegisterOptions<TFieldValues>;
  label?: string;
  placeholder?: string;
} & Omit<SelectProps, "name" | "value" | "onChange">;

const ControllerSelect = <TFieldValues extends FieldValues>({
  name,
  control,
  options,
  rules,
  ...rest
}: ControllerSelectProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error} size="small">
          <CustomSelectBox native {...field} {...rest} displayEmpty>
            <MenuItem value="" component="option">
              Select
            </MenuItem>

            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                component="option"
              >
                {option.label}
              </MenuItem>
            ))}
          </CustomSelectBox>
          {error?.message && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default ControllerSelect;
