import React, { useState } from "react";
import { useMutation } from "react-query";
import { addPhoto } from "../../../services/photo.service";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
// import CommonInputSmall from "../../../common/CommonInputSmall";
import CustomSelector from "../../../common/CustomSelector";
import uploadFile from "../../../utils/uploadFile";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PreviewDownloadImage from "../../../common/PreviewDownloadImage";
import { LoadingButton } from "@mui/lab";

import CommonInputSmall from "../../../common/CommonInputSmall";

const PhotoForm = ({
  refresh,
  close,
  initialValues,
  update,
  addNew = true,
}) => {
  const des = initialValues.withDate ? "true" : "false";
  const [date, setDate] = useState(des);
  const [loading, setLoading] = useState(false); // for image upload

  const { mutate, isLoading } = useMutation(addPhoto, {
    onSuccess: (success) => {
      refresh();
      close();
      toast.success(success?.data?.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  // Formik form Adding photo different types
  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues,
    onSubmit: (data) => {
      // console.log("submitted", data, values);
      let body = {
        photo: data.photo,
      };
      if (!addNew) {
        body.photoDocId = data.photoDocId;
      }
      if (date === "true") {
        body.withDate = true;
        body.date = data.date;
        body.eventName = data.eventName;
        body.language = data.language;
        body.tags = String(data.tags).split(",");
        body.name = data.eventName;
      } else {
        body.withDate = false;
        if (data.categoryTag === "quotes") {
          body.language = data.language;
          body.tags = String(data.tags).split(",");
          body.name = data.quotesName;
          body.categoryTag = data.categoryTag;
          // body.subCategory = data.subCategory;
          body.quotesName = data.quotesName;
          body.categoryDocId = data.categoryDocId;
          body.subCategoryName = data.subCategoryName;
          body.subCategoryDocId = data.subCategoryDocId;
        } else if (data.categoryTag === "trending") {
          body.language = data.language;
          body.tags = String(data.tags).split(",");
          body.name = data.trendName;
          body.categoryTag = data.categoryTag;
          body.categoryName = data.categoryName;
          body.categoryDocId = data.categoryDocId;
          body.subCategoryName = data.subCategoryName;
          body.subCategoryDocId = data.subCategoryDocId;
          body.trendName = data.trendName;
        } else if (data.categoryTag === "politics") {
          body.language = data.language;
          body.tags = String(data.tags).split(",");
          body.name = data.leaderName;
          body.categoryTag = data.categoryTag;
          body.categoryName = data.categoryName;
          body.categoryDocId = data.categoryDocId;
          body.partyName = data.partyName;
          body.partyDocId = data.partyDocId;
          body.leaderName = data.leaderName;
        } else {
          body.language = data.language;
          body.tags = String(data.tags).split(",");
          body.name = data.businessName;
          body.categoryTag = data.categoryTag;
          body.categoryName = data.categoryName;
          body.categoryDocId = data.categoryDocId;
          body.subCategoryName = data.subCategoryName;
          body.subCategoryDocId = data.subCategoryDocId;
          body.businessName = data.businessName;
        }
      }
      //console.log("Form Data is : ", body);
      if (addNew) {
        mutate(body);
      } else {
        update(body);
      }
    },
  });

  // Extracting userDocId
  const userDetail = useSelector((store) => store.auth.userDetail);
  let userDocId = userDetail?._id;
  // Handelling The Upload
  const handleUpload = async (e) => {
    setLoading(true);
    try {
      let s3FilePath = `postAndShare/photo/${userDocId}/${e.target.files[0].name}`;
      let attachmentUrl = await uploadFile(
        e.target.files[0],
        s3FilePath,
        e.target.files[0].type
      );
      await setValues((prev) => ({ ...prev, photo: attachmentUrl.fileURL }));

      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  const handleDeleteImage = (img) => {
    setValues((prev) => ({ ...prev, photo: "" }));
  };

  return (
    <Box sx={{ px: 3 }}>
      <Stack direction={"row"}>
        {addNew && (
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ marginBottom: "10px" }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
          >
            <FormControlLabel
              value={"true"}
              control={<Radio />}
              label="With Date"
            />
            <FormControlLabel
              value={"false"}
              control={<Radio />}
              label="Without Date"
            />
          </RadioGroup>
        )}
        {!addNew && <h1 style={{ marginBottom: "10px" }}></h1>}
      </Stack>
      {/* Upload Image start */}
      {values.photo ? (
        <PreviewDownloadImage
          src={values.photo}
          deleteIcon={true}
          handleDelete={() => handleDeleteImage(values.photo)}
        />
      ) : (
        <LoadingButton
          variant="outlined"
          component="label"
          color="secondary"
          size="large"
          sx={{
            mt: 1,
            textTransform: "capitalize",
            px: 2,
            py: 0.5,
            borderRadius: "20px",
          }}
          loading={loading}
        >
          <img src={"./upload-icon.png"} alt="upload" />
          <input hidden type="file" onChange={handleUpload} accept="image/*" />
        </LoadingButton>
      )}

      {/*  Upload Image End here */}

      <Box component={"form"} onSubmit={handleSubmit}>
        {date === "true" ? (
          <>
            <Stack spacing={2} direction={"row"}>
              <Box width="50%">
                <CommonInputSmall
                  name="date"
                  label="Date"
                  type="date"
                  value={values?.date.split("T")[0]}
                  // value="2024-03-01"
                  handeler={handleChange}
                />
              </Box>
              <Box width="50%">
                <CommonInputSmall
                  name="eventName"
                  label="Event Name"
                  value={values?.eventName}
                  handeler={handleChange}
                />
              </Box>
            </Stack>
            <Stack
              spacing={2}
              sx={{ py: 2 }}
              direction={"row"}
              justifyContent={""}
            >
              <Box width="50%">
                <CustomSelector
                  label="Languages"
                  name="language"
                  value={values.language}
                  handeler={handleChange}
                  values={["English", "Hindi"]}
                />
              </Box>
              <Box width="50%">
                <CommonInputSmall
                  name="tags"
                  label="Tags"
                  value={values?.tags}
                  handeler={handleChange}
                />
                {/* <MultiSelectInput handeler={handleChange} name="tag" label="Tags"/> */}
              </Box>
            </Stack>
          </>
        ) : (
          <>
            <Stack spacing={2} direction={"row"}>
              <Box width="50%">
                <CustomSelector
                  label="Languages"
                  name="language"
                  value={values.language}
                  handeler={handleChange}
                  values={["English", "Hindi"]}
                />
              </Box>

              <Box width="50%">
                <CustomSelector
                  label="Category Tag"
                  name="categoryTag"
                  value={values.categoryTag}
                  handeler={handleChange}
                  values={["quotes", "trending", "politics", "business"]}
                />
              </Box>
            </Stack>
            <Stack
              spacing={2}
              sx={{ py: 2 }}
              direction={"row"}
              justifyContent={""}
            >
              <Box width="50%">
                <CustomSelector
                  label="Category Name"
                  name="categoryName"
                  value={values.categoryName}
                  handeler={handleChange}
                  values={["quotes", "trending", "politics", "business"]}
                />
              </Box>
              <Box width="50%">
                {values.categoryTag == "politices" ? (
                  <>
                    <CustomSelector
                      label="Party Name"
                      name="partyName"
                      value={values.partyName}
                      handeler={handleChange}
                      values={["politices", "quotes"]}
                    />
                  </>
                ) : (
                  <>
                    <CustomSelector
                      label={
                        values.categoryTag !== "business"
                          ? "Sub Category"
                          : "Sub Category (Bussiness Type)"
                      }
                      name="subCategoryName"
                      value={values.subCategoryName}
                      handeler={handleChange}
                      values={["politices", "quotes"]}
                    />
                  </>
                )}
              </Box>
            </Stack>
            <Stack
              spacing={2}
              sx={{ py: 2 }}
              direction={"row"}
              justifyContent={""}
            >
              <Box width="50%">
                {values.categoryTag == "quotes" && (
                  <CommonInputSmall
                    name="quotesName"
                    label="Quote Name"
                    type="text"
                    value={values?.quotesName}
                    // value="2024-03-01"
                    handeler={handleChange}
                  />
                )}
                {values.categoryTag == "trending" && (
                  <CommonInputSmall
                    name="trendName"
                    label="Trend Name"
                    type="text"
                    value={values?.trendName}
                    // value="2024-03-01"
                    handeler={handleChange}
                  />
                )}
                {values.categoryTag == "politics" && (
                  <CommonInputSmall
                    name="leaderName"
                    label="Leader Name"
                    type="text"
                    value={values?.leaderName}
                    // value="2024-03-01"
                    handeler={handleChange}
                  />
                )}
                {values.categoryTag == "business" && (
                  <CommonInputSmall
                    name="businessName"
                    label="Bussiness Name"
                    type="text"
                    value={values?.businessName}
                    // value="2024-03-01"
                    handeler={handleChange}
                  />
                )}
              </Box>
              <Box width="50%">
                <CommonInputSmall
                  name="tags"
                  label="Tags"
                  value={values?.tags}
                  handeler={handleChange}
                />
                {/* <MultiSelectInput handeler={handleChange} name="tag" label="Tags"/> */}
              </Box>
            </Stack>
          </>
        )}
        {/* Add Photo Button*/}
        <Stack direction="row" justifyContent={"flex-end"}>
          <Button
            type="submit"
            sx={{
              width: "177px",
              p: 1,
              borderRadius: "10px",
              background: " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
              flexShrink: 0,
              marginBottom: "40px",
            }}
            variant="contained"
            size="small"
          >
            {addNew ? "Add Photo" : "Update Photo"}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default PhotoForm;
