import { Box, Button, styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const RouteNotFound = () => {
  return (
    <>
      <Wrapper>
        <Box>
          <img src="/not-found.png" alt="not-found" />
        </Box>
        <Typography className="title">Page Not Found</Typography>
        <Typography className="sub-title">
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </Typography>
        <Button
          to="/"
          LinkComponent={Link}
          variant="contained"
          sx={{ boxShadow: "none", borderRadius: "15px" }}
          size="large"
        >
          Home Page
        </Button>
      </Wrapper>
    </>
  );
};

export default RouteNotFound;
const Wrapper = styled(Box)`
  background-color: #f5f5f7;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  img {
    height: 180px;
    object-fit: contain;
  }
  .title {
    font-size: 36px;
    color: #1a1c16;
  }
  .sub-title {
    font-size: 16px;
    color: #44483d;
    width: 300px;
    text-align: center;
  }
`;
