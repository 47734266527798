import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { styled, Box } from "@mui/material";
import { SIZES_CONSTANTS } from "../utils/constants";

const Layout = () => {
  return (
    <>
      <StyledWrapper>
        {/* sidebar  */}
        <div className="left_sidebar">
          <Sidebar />
        </div>

        <div>
          <div className="right_side__content">
            <Outlet />
          </div>
        </div>
      </StyledWrapper>
    </>
  );
};

export default Layout;
const StyledWrapper = styled(Box)`
  display: flex;
  overflow: hidden;
  .left_sidebar {
    width: ${SIZES_CONSTANTS.sidebar_width};
    height: 100vh;
    background: #fff;
  }
  .right_side__content {
    width: calc(100vw - ${SIZES_CONSTANTS.sidebar_width});
    overflow-y: auto;

    height: 100vh;
    background: #f2f6f6;
  }
`;
