import {
  Box,
  CircularProgress,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import logo from "./img/logo.png";
import background from "./img/background.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import {
  SendOTPonMobile,
  staffSignInWithOTP,
} from "../../services/auth.service";
import { useEffect, useState } from "react";
import { setLoginState, setUserDetails } from "../../services/redux/authSlice";
import { setIsAuthenticated } from "../../services/redux/authSlice";
import { LoadingButton } from "@mui/lab";
import { getUserProfile } from "../../services/staff.service";
import { onError } from "../../utils/helpers";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ControllerInput from "../../common/ControllerInput";
import { Helmet } from "react-helmet-async";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(59);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [otp, setOTP] = useState(false);
  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: { mobile: "", request_id: "abcd", OTP: "" },
  });
  const handleResend = () => {
    SendOTPonMobileMutate({ mobileNumber: String(getValues().mobile) });
  };
  const handleChangeNumber = () => {
    setOTP(false);
    setValue("OTP", "");
  };
  const onSubmit = (values) => {
    let body;
    if (!otp) {
      body = { mobileNumber: values.mobile.toString() };
      SendOTPonMobileMutate(body);
    } else {
      body = {
        mobileNumber: values.mobile.toString(),
        request_id: values.request_id,
        OTP: values.OTP.toString(),
      };
      staffSignInWithOTPMutate(body);
    }
  };
  const { refetch: setUserProfile } = useQuery({
    queryKey: ["getUserProfile"],
    queryFn: () => getUserProfile(),
    onSuccess: (user) => {
      dispatch(setUserDetails(user.data.obj));
    },
    enabled: false,
  });
  // Sending OTP
  const { mutate: SendOTPonMobileMutate, isLoading: sendOTPLoading } =
    useMutation({
      mutationFn: SendOTPonMobile,
      mutationKey: ["SendOTPonMobile"],
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        setValue("request_id", success?.data?.request_id);
        setOTP(true);
        setTimeLeft(59);
        setIsResendEnabled(false);
      },
      onError: onError,
    });
  // Verifyning OTP
  const {
    mutate: staffSignInWithOTPMutate,
    isLoading: staffSignInWithOTPLoading,
  } = useMutation(staffSignInWithOTP, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      dispatch(setLoginState(success.data));
      dispatch(setIsAuthenticated(true));
      navigate("/");
    },
    onError,
  });
  useEffect(() => {
    if (otp) {
      let timer;
      if (timeLeft > 0) {
        timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      } else {
        setIsResendEnabled(true);
      }

      return () => clearTimeout(timer);
    }
  }, [timeLeft, otp]);
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <StyleWrapper>
        <Stack width={"100%"} direction={"row"} sx={{ height: "100vh" }}>
          {/* Background or Logo Section start*/}
          <Stack
            sx={{
              width: "50%",
              backgroundColor: "#6FFFFF",
              backgroundImage: `url(${background})`, // Use `url()` to set the background image
              backgroundSize: "cover", // Optional: Set background size
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "264px",
                height: "264px",
                borderRadius: "100%",
                backgroundColor: "#020C0A1A",
              }}
            >
              <Stack
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "234px",
                  height: "234px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "100%",
                }}
              >
                <Box sx={{ p: 3 }}>
                  <img src={logo} alt="logo" />
                </Box>
              </Stack>
            </Stack>
          </Stack>
          {/* Background or Logo Section end*/}

          {/*Form stack */}
          <Stack width={"50%"} sx={{ padding: "50px" }}>
            <Stack
              sx={{ flex: 1 }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <form
                onSubmit={handleSubmit(onSubmit)}
                sx={{ padding: "0 50px" }}
              >
                <Typography className="title" variant="h4">
                  LOGIN ACCOUNT
                </Typography>
                <Typography className="sub-title">
                  Enter the Mobile Number to login
                </Typography>

                <Stack>
                  <ControllerInput
                    disabled={otp}
                    control={control}
                    name="mobile"
                    type="number"
                    placeholder="XXXXXXXXXX"
                    numberLenght={10}
                    rules={{
                      required: "Mobile number required",
                      minLength: {
                        value: 10,
                        message: "Number should be 10 digit",
                      },
                    }}
                  />
                </Stack>
                {otp && (
                  <Stack>
                    <Typography
                      className="change_number_text"
                      onClick={handleChangeNumber}
                    >
                      Change number
                    </Typography>
                    <ControllerInput
                      control={control}
                      name="OTP"
                      placeholder="XXXX"
                      numberLenght={4}
                      rules={{
                        required: "Mobile number required",
                        minLength: {
                          value: 4,
                          message: "Number should be 10 digit",
                        },
                      }}
                    />
                    {!isResendEnabled ? (
                      <Typography className="timer_text">
                        Resend OTP in {timeLeft} seconds
                      </Typography>
                    ) : sendOTPLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Typography
                        className="change_number_text"
                        onClick={handleResend}
                      >
                        Resend OTP
                      </Typography>
                    )}
                  </Stack>
                )}

                <Stack>
                  {otp ? (
                    <LoadingButton
                      loading={staffSignInWithOTPLoading}
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mt: 2.5 }}
                    >
                      Verify OTP
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      loading={sendOTPLoading}
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mt: 2.5 }}
                    >
                      Send OTP
                    </LoadingButton>
                  )}
                </Stack>
              </form>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-around"}>
              <Link to={"/privacy-policy"} className="link">
                <Typography>Privacy Policy</Typography>
              </Link>
              <Link to={"/term-and-condition"} className="link">
                <Typography>Terms & Condition</Typography>
              </Link>
              <Link to={"/refund-policy"} className="link">
                <Typography>Refund Policy</Typography>
              </Link>
            </Stack>
            {/*Form Box */}
          </Stack>
        </Stack>
      </StyleWrapper>
    </>
  );
};

export default Login;

const StyleWrapper = styled(Box)`
  .bottom-info {
    color: rgba(2, 12, 10, 0.75);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
  }

  .title {
    text-align: center;
    color: #020c0a;
    font-size: 34.119px; /* Note: Remove the colon (:) after the value */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.024px;
    text-transform: uppercase;
  }
  .sub-title {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: #020c0a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 1.024px;
    margin-top: 16px;
    opacity: 0.75;
    margin-bottom: 35px;
    font-family: inherit;
  }
  .change_number_text {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: blue;
    margin-bottom: 8px;
    margin-left: 7px;
    :hover {
      font-weight: 700;
    }
    .timer_text {
      font-size: 14px;
      color: rgba(2, 12, 10, 0.75);
      margin-bottom: 8px;
      margin-left: 7px;
    }
  }
  .link {
    text-decoration: none;
    p {
      color: #999;
      transition: all 0.5s;
      :hover {
        color: #020c0a;
        text-decoration: underline;
      }
    }
  }
`;
