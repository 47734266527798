import { styled, Box, Typography, Stack } from "@mui/material";
import { SIDEBAR_ITEMS, SIZES_CONSTANTS } from "../utils/constants";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogout } from "../services/redux/authSlice";
import { Logout } from "@mui/icons-material";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(setLogout());
  };
  return (
    <StyledWrapper>
      <Box flexGrow={1}>
        <div className="title_text_wrapper">
          <Typography className="title_text">Post and Share</Typography>
        </div>
        {SIDEBAR_ITEMS?.map((item, index) => (
          <Link
            to={item.path}
            style={{ textDecoration: "none", color: "inherit" }}
            key={index}
          >
            <Stack
              direction={"row"}
              gap={2}
              className={`menut_item ${
                location.pathname === item.path && "menu_item__active"
              }`}
            >
              {item.icon}
              <Typography className="sidebar_menu_name">
                {item?.name}
              </Typography>
            </Stack>
          </Link>
        ))}
      </Box>
      <Box sx={{ pb: 2 }}>
        <Stack
          direction={"row"}
          gap={2}
          className="menut_item"
          onClick={logout}
          sx={{ cursor: "pointer" }}
        >
          <Logout className="icon" />
          <Typography className="sidebar_menu_name">Log Out</Typography>
        </Stack>
      </Box>
    </StyledWrapper>
  );
};

export default Sidebar;
const StyledWrapper = styled(Box)`
  height: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  .title_text_wrapper {
    height: ${SIZES_CONSTANTS.navbarHeight};

    display: flex;
    justify-content: center;
    align-items: center;
    .title_text {
      color: #1b5447;
      text-align: center;
      font-size: 23px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .menut_item {
    padding: 6px;
    margin: 3px 0;
    border-radius: 5px;
    :hover {
      background-color: #8feed842;
      .icon,
      .sidebar_menu_name {
        color: #000 !important;
      }
    }
  }
  .menu_item__active {
    background-color: #8feed842;
    .icon,
    .sidebar_menu_name {
      color: #000 !important;
    }
  }

  .maker_text {
    text-align: center;
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .icon {
    color: #777;
  }
  .sidebar_menu_name {
    color: #777;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
  }
`;
