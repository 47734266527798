import React from "react";
import Fallback from "../../common/Fallback";

const Category = React.lazy(() => import("./Category"));

export const CategoryPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Category {...props} />
  </React.Suspense>
);
