import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
type Props = {
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  label: string;
  disabled?: boolean;
};
const FilterCheckbox: React.FC<Props> = ({
  checked,
  onChange = () => {},
  label,
  disabled = false,
}) => {
  return (
    <FormControlLabel
      disabled={disabled}
      label={label}
      control={
        <Checkbox color="primary" checked={checked} onChange={onChange} />
      }
    />
  );
};

export default FilterCheckbox;
