import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { MainRoute } from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./services/store";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import theme from "./utils/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HelmetProvider } from "react-helmet-async";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ReduxProvider store={store}>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover={false}
              theme="colored"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <HelmetProvider>
                <MainRoute />
              </HelmetProvider>
            </LocalizationProvider>
          </ReduxProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
