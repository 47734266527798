import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "rgba(27, 84, 71, 100%)",
      //   rnPrimary: "#00CC8C",
      //   rnsecondary: "#184D41",
      //   rnplaceholder: "#fff",
      //   rnoutline: "rgba(24,77,65,0.5)",
      //   rnsurface: "rgba(24,77,65,0.5)",
      //   rnsurfaceVariant: "rgba(24,77,65,0.5)",
      //   rnbackground: "#f5f5f5",
    },
    secondary: {
      main: "#00CC8C",
    },
    text: {
      primary: "#0C2F49",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
        fullWidth: true,
        displayEmpty: true,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
  },
});
export default theme;
