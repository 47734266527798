import { axiosInstance } from "./axios";

const BASE_URL = process.env.REACT_APP_BASE;
const DASHBOARD_URL = `${BASE_URL}/dashboard`;
export const getUserCount = () => {
  return axiosInstance.get(`${DASHBOARD_URL}/getUserCount`);
};
export const getStaffCount = () => {
  return axiosInstance.get(`${DASHBOARD_URL}/getStaffCount`);
};

export const getTotalContent = () => {
  return axiosInstance.get(`${DASHBOARD_URL}/getTotalContent`);
};

export const getTotalBusinessCount = () => {
  return axiosInstance.get(`${DASHBOARD_URL}/getTotalBusinessCount`);
};
