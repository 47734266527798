import { axiosInstance } from "./axios";
const BASE_URL = process.env.REACT_APP_BASE;
const FRAME_URL = `${BASE_URL}/admin/frame`;
export const addFrame = (body) => {
  return axiosInstance.post(`${FRAME_URL}/addOrgFrame`, body);
};
export const getOrgFrame = (params) => {
  return axiosInstance.get(`${FRAME_URL}/getOrgFrame`, { params });
};
export const updateFrame = (body) => {
  return axiosInstance.put(`${FRAME_URL}/updateOrgFrame`, body);
};
export const updateContentLocation = (body) => {
  return axiosInstance.put(`${FRAME_URL}/updateContentLocation`, body);
};
export const deleteFrame = (frameDocId) =>
  axiosInstance.delete(`${FRAME_URL}/deleteOrgFrame/${frameDocId}`);
