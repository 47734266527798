import { Box, styled, Stack, Paper, Typography, Button } from "@mui/material";
import { useState } from "react";
import FrameTable from "./FrameTable";
import PhotoTable from "./PhotoTable";

const Upload = () => {
  const [state, setState] = useState("frame");

  return (
    <StyledWrapper>
      {/* Selcting Box */}
      <Box>
        <Stack direction={"row"} spacing={2}>
          <Button
            sx={{ m: 0, p: 0 }}
            onClick={() => {
              setState("frame");
            }}
            className={state === "frame" ? "active" : ""}
          >
            <Paper square className="over-view">
              <Typography className="doc-type">Frames</Typography>
            </Paper>
          </Button>
          <Button
            sx={{ m: 0, p: 0 }}
            onClick={() => {
              setState("photo");
            }}
            className={state === "photo" ? "active" : ""}
          >
            <Paper square className="over-view">
              <Typography className="doc-type">Photos</Typography>
            </Paper>
          </Button>
        </Stack>
      </Box>
      {/* All tables */}

      <Stack>
        {state === "frame" && <FrameTable />}
        {state === "photo" && <PhotoTable />}
        {/*{state ==="video" && <VideoTable />}*/}
      </Stack>
    </StyledWrapper>
  );
};

export default Upload;

const StyledWrapper = styled(Box)`
  padding: 25px 30px;
  .over-view {
    width: 100.35px;
    border-radius: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.3);
    background: #fff;
  }
  .doc-type {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
  }
  .active .over-view {
    background-color: #5bd5b9;
  }
  .active .doc-type {
    color: #fff;
  }
  a {
    text-decoration: none;
  }
  .button-filter {
    width: 122.823px;
    height: 36.062px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #1a5346;
    text-transform: capitalize;
  }
  .button-add-frame {
    text-transform: capitalize;
    width: 173.371px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.05);
    background: #1a5346;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .model-upload-button {
    width: 123.091px;
    height: 122.035px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(21, 70, 58, 0.2);
    background: rgba(90, 212, 183, 0.1);
  }
`;
