import { Box, IconButton, Stack, styled } from "@mui/material";
import React from "react";
type Prosps = {
  showView?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  onClickView?: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
};
const TableActionButtons: React.FC<Prosps> = ({
  showView = true,
  showEdit = true,
  showDelete = true,
  onClickView,
  onClickEdit,
  onClickDelete,
}) => {
  return (
    <Wrapper>
      <Stack direction="row" spacing={0.5} sx={{ m: 0 }}>
        {showView && (
          <IconButton sx={{ p: 0 }} onClick={onClickView}>
            <img src="./action_view.png" alt="view" />
          </IconButton>
        )}
        {showEdit && (
          <IconButton sx={{ p: 0 }} onClick={onClickEdit}>
            <img src="./action_edit.png" alt="edit" />
          </IconButton>
        )}
        {showDelete && (
          <IconButton sx={{ p: 0 }} onClick={onClickDelete}>
            <img src="./action_delete.png" alt="delete" />
          </IconButton>
        )}
      </Stack>
    </Wrapper>
  );
};

export default TableActionButtons;
const Wrapper = styled(Box)`
  img {
    height: 35px;
    width: 35px;
    object-fit: contain;
  }
`;
