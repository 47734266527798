import { Stack, styled, Typography } from "@mui/material";
import React from "react";

type Props = {
  label: string;
  value: string;
};
const AppliedFilterChip: React.FC<Props> = ({ label, value }) => {
  return (
    <Wrapper direction={"row"} gap={1}>
      <Typography
        sx={{ fontSize: { xs: "8px", md: "12px" } }}
        fontWeight={600}
        textTransform={"capitalize"}
      >
        <span>{label}</span>:- {value}
      </Typography>
      {/* <Close className="close" onClick={onClose} /> */}
    </Wrapper>
  );
};
export default AppliedFilterChip;
const Wrapper = styled(Stack)`
  display: flex;
  align-items: center;
  background-color: #0908081e;
  border-radius: 3px;
  padding: 1px 2px;
  span {
    opacity: 0.8;
  }
  .close {
    background-color: #fff;
    font-size: 12px;
    color: #f40303;
    border-radius: 50%;
    cursor: pointer;
  }
`;
