import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";

interface CommonFilterDialogProps {
  open?: boolean;
  onClose?: () => void;
  width?: string;
  title?: string;
  loading?: boolean;
  handleSaveAndView?: () => void;
  children?: ReactNode;
}

const CommonFilterDialog: React.FC<CommonFilterDialogProps> = ({
  open = false,
  onClose = () => {},
  width = "400px",
  title = "Filter",
  loading = false,
  handleSaveAndView = () => {},
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: { xs: "98%", md: width } },
      }}
    >
      <DialogTitle sx={{ p: "0 !important" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: 1, px: 2 }}
        >
          <Typography
            sx={{ fontSize: "16px", fontWeight: 600 }}
            color="primary"
          >
            {title}
          </Typography>
          {loading ? (
            <CircularProgress size={25} />
          ) : (
            <Typography
              onClick={handleSaveAndView}
              sx={{
                fontSize: "16px",
                color: "#28789c",
                textTransform: "capitalize",
                cursor: "pointer",
              }}
            >
              Save & View
            </Typography>
          )}
        </Stack>
        <Divider sx={{ backgroundColor: "#0C2F49", mb: 1 }} />
      </DialogTitle>
      <DialogContent sx={{ p: "10px" }}>{children}</DialogContent>
    </Dialog>
  );
};

export default CommonFilterDialog;
