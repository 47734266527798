import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "react-query";
import DialogHeader from "../../common/DialogHeader";
import LocationName from "./LocationName";
import { updateContentLocation } from "../../services/frame.service";
import { toast } from "react-toastify";

const logoFormikValidationSchema = yup.object({
  x_axis: yup.string().required("Required"),
  y_axis: yup.string().required("Required"),
  fontColor: yup.string().optional(""),
  fontWeight: yup.string().required("Required"),
  fontSize: yup.string().required("Required"),
  fontStyle: yup.string().required("Required"),
});

const emailFormikValidationSchema = yup.object({
  x_axis: yup.string().required("Required"),
  y_axis: yup.string().required("Required"),
  fontColor: yup.string().optional(""),
  fontWeight: yup.string().required("Required"),
  fontSize: yup.string().required("Required"),
  fontStyle: yup.string().required("Required"),
});

const UpdateContentLocation = ({
  refetch = () => {},
  open,
  handleClose = () => {},
  data,
}) => {
  const logoFormik = useFormik({
    initialValues: {
      show: true,
      x_axis: "5",
      y_axis: "2",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "12",
      fontStyle: "normal",
      width: "",
    },
    validationSchema: logoFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const emailFormik = useFormik({
    initialValues: {
      show: true,
      x_axis: "04",
      y_axis: "303",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
      width: "",
    },
    validationSchema: emailFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const adressFormik = useFormik({
    initialValues: {
      show: true,
      x_axis: "07",
      y_axis: "351",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
      width: "",
    },
    validationSchema: emailFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const whatsappFormik = useFormik({
    initialValues: {
      show: true,
      x_axis: "270",
      y_axis: "340",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
      width: "",
    },
    validationSchema: emailFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const phoneFormik = useFormik({
    initialValues: {
      show: true,
      x_axis: "237",
      y_axis: "286",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
      width: "",
    },
    validationSchema: emailFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const websiteFormik = useFormik({
    initialValues: {
      show: true,
      x_axis: "07",
      y_axis: "326",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
      width: "",
    },
    validationSchema: emailFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [openedCard, setOpenedCard] = useState(null);

  const {
    mutate: updateContentLocationMutate,
    isLoading: updateContentLocationLoading,
  } = useMutation(updateContentLocation, {
    onSuccess: (success) => {
      handleClose();
      refetch();
      toast.success(success?.data?.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleLocationUpdate = () => {
    let body = {
      frameDocId: data?._id,
      contentLocation: {
        logo: {
          show: logoFormik?.values?.show,
          x_axis: logoFormik?.values?.x_axis,
          y_axis: logoFormik?.values?.y_axis,
          fontColor: String(logoFormik?.values?.fontColor),
          fontWeight: logoFormik?.values?.fontWeight,
          fontSize: logoFormik?.values?.fontSize,
          fontStyle: logoFormik?.values?.fontStyle,
          width: null,
        },
        email: {
          show: emailFormik?.values?.show,
          x_axis: emailFormik?.values?.x_axis,
          y_axis: emailFormik?.values?.y_axis,
          fontColor: String(emailFormik?.values?.fontColor),
          fontWeight: emailFormik?.values?.fontWeight,
          fontSize: emailFormik?.values?.fontSize,
          fontStyle: emailFormik?.values?.fontStyle,
          width: emailFormik?.values?.width,
        },
        address: {
          show: adressFormik?.values?.show,
          x_axis: adressFormik?.values?.x_axis,
          y_axis: adressFormik?.values?.y_axis,
          fontColor: String(adressFormik?.values?.fontColor),
          fontWeight: adressFormik?.values?.fontWeight,
          fontSize: adressFormik?.values?.fontSize,
          fontStyle: adressFormik?.values?.fontStyle,
          width: adressFormik?.values?.width,
        },
        whatsAppNumber: {
          show: whatsappFormik?.values?.show,
          x_axis: whatsappFormik?.values?.x_axis,
          y_axis: whatsappFormik?.values?.y_axis,
          fontColor: String(whatsappFormik?.values?.fontColor),
          fontWeight: whatsappFormik?.values?.fontWeight,
          fontSize: whatsappFormik?.values?.fontSize,
          fontStyle: whatsappFormik?.values?.fontStyle,
          width: whatsappFormik?.values?.width,
        },
        mobileNumber: {
          show: phoneFormik?.values?.show,
          x_axis: phoneFormik?.values?.x_axis,
          y_axis: phoneFormik?.values?.y_axis,
          fontColor: String(phoneFormik?.values?.fontColor),
          fontWeight: phoneFormik?.values?.fontWeight,
          fontSize: phoneFormik?.values?.fontSize,
          fontStyle: phoneFormik?.values?.fontStyle,
          width: phoneFormik?.values?.width,
        },
        website: {
          show: websiteFormik?.values?.show,
          x_axis: websiteFormik?.values?.x_axis,
          y_axis: websiteFormik?.values?.y_axis,
          fontColor: String(websiteFormik?.values?.fontColor),
          fontWeight: websiteFormik?.values?.fontWeight,
          fontSize: websiteFormik?.values?.fontSize,
          fontStyle: websiteFormik?.values?.fontStyle,
          width: websiteFormik?.values?.width,
        },
      },
    };
    updateContentLocationMutate(body);
  };
  const getCommonStyles = (formik) => ({
    fontSize: `${formik?.values?.fontSize}px` ?? "12px",
    fontWeight: `${formik?.values?.fontWeight}` ?? "500",
    position: "absolute",
    zIndex: 1,
    color: formik?.values?.fontColor ?? "#000000",
    top: formik?.values?.y_axis ? `${formik?.values?.y_axis}px` : "10px",
    left: formik?.values?.x_axis ? `${formik?.values?.x_axis}px` : "10px",
    fontStyle: formik?.values?.fontStyle
      ? `${formik?.values?.fontStyle}`
      : "normal",
    ...(formik?.values?.width &&
      Number(formik?.values?.width) > 0 && {
        width: `${formik?.values?.width}px`,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }),
  });
  useEffect(() => {
    if (open && data?.contentLocation) {
      logoFormik?.setValues(data?.contentLocation?.logo);
      emailFormik?.setValues(data?.contentLocation?.email);
      adressFormik?.setValues(data?.contentLocation?.address);
      whatsappFormik?.setValues(data?.contentLocation?.whatsAppNumber);
      phoneFormik?.setValues(data?.contentLocation?.mobileNumber);
      websiteFormik?.setValues(data?.contentLocation?.website);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "60vw",
          },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Update Content Location"
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ m: 2 }}>
            <Stack direction={{ xs: "column", lg: "row" }} gap={2}>
              <Stack
                style={{
                  position: "relative",
                }}
              >
                <img
                  src={data?.framePic}
                  alt="frame"
                  style={{
                    position: "static",
                    height: data.height ?? 375,
                    width: data.width ?? 375,
                    background: "#8888",
                  }}
                />
                {/* logo */}
                {logoFormik.values?.show && (
                  <div style={getCommonStyles(logoFormik)}>
                    <img width="60px" src="./frame-log.jpg" alt="logo" />
                  </div>
                )}
                {/* email */}
                {emailFormik.values?.show && (
                  <Typography sx={getCommonStyles(emailFormik)}>
                    contact@postandshare.com
                  </Typography>
                )}
                {/* adress */}
                {adressFormik.values?.show && (
                  <Typography sx={getCommonStyles(adressFormik)}>
                    Tamsa Marg Meeranpur Akbarpur, Ambedkar Nagar, Uttar Pradesh
                  </Typography>
                )}
                {/* whatsapp */}
                {whatsappFormik.values?.show && (
                  <Typography sx={getCommonStyles(whatsappFormik)}>
                    911234567890
                  </Typography>
                )}
                {/* phone number */}
                {phoneFormik.values?.show && (
                  <Typography sx={getCommonStyles(phoneFormik)}>
                    911234567890
                  </Typography>
                )}
                {/* website */}
                {websiteFormik.values?.show && (
                  <Typography sx={getCommonStyles(websiteFormik)}>
                    www.postandshare.com
                  </Typography>
                )}
              </Stack>
              <Box sx={{ maxHeight: "375px", overflowY: "auto" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* for logo purpose */}

                    <LocationName
                      item={"Logo"}
                      openedCard={openedCard}
                      setOpenedCard={setOpenedCard}
                      formik={logoFormik}
                    />
                    {/* for email pupose */}
                    <LocationName
                      item={"Email"}
                      openedCard={openedCard}
                      setOpenedCard={setOpenedCard}
                      formik={emailFormik}
                    />
                    {/* for address puporse */}
                    <LocationName
                      item={"Address"}
                      formik={adressFormik}
                      openedCard={openedCard}
                      setOpenedCard={setOpenedCard}
                    />
                    {/* for whatsapp purpose */}
                    <LocationName
                      item={"Whatsapp"}
                      formik={whatsappFormik}
                      openedCard={openedCard}
                      setOpenedCard={setOpenedCard}
                    />
                    {/* for phone purpose */}
                    <LocationName
                      item={"Phone"}
                      formik={phoneFormik}
                      openedCard={openedCard}
                      setOpenedCard={setOpenedCard}
                    />
                    {/* for website purpose */}
                    <LocationName
                      item={"Website"}
                      formik={websiteFormik}
                      openedCard={openedCard}
                      setOpenedCard={setOpenedCard}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => {
              handleLocationUpdate();
            }}
            loading={updateContentLocationLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateContentLocation;
