import { Box, Paper, Stack, Typography, styled } from "@mui/material";
import { useQuery } from "react-query";

import Navbar from "../../components/Navbar";
import {
  getStaffCount,
  getTotalBusinessCount,
  getTotalContent,
  getUserCount,
} from "../../services/dashboard.service";
import { Helmet } from "react-helmet-async";
const Dashboard = () => {
  const { data: user } = useQuery({
    queryKey: "getUserCount",
    queryFn: () => getUserCount(),
    onSuccess: (userInfo) => {},
    onError: (error) => {},
  });
  const { data: staff } = useQuery({
    queryKey: "getStaffCount",
    queryFn: () => getStaffCount(),
    onSuccess: (staff) => {},
    onError: (error) => {},
  });
  const { data: events } = useQuery({
    queryKey: "getTotalContent",
    queryFn: () => getTotalContent(),
    onSuccess: (events) => {},
    onError: (error) => {},
  });
  const { data: business } = useQuery({
    queryKey: "getTotalBusinessCount",
    queryFn: () => getTotalBusinessCount(),
    onSuccess: (events) => {},
    onError: (error) => {},
  });
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Navbar />
      <StyledWrapper>
        <Stack>
          <Stack
            width={{ sm: "100%", ms: "50%" }}
            direction={{ sm: "row", ms: "column" }}
            spacing={2}
          >
            {/* TODO : Have design the Boxes  */}
            <Paper square className="over-view">
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack>
                  <Typography variant="body2" className="over-view-data">
                    Users
                  </Typography>

                  {user?.data?.list?.map(
                    (item, index) =>
                      item._id !== null && (
                        <Typography
                          key={index}
                          variant="body2"
                          className="over-view-label"
                        >
                          {item._id || "Unknown"}
                        </Typography>
                      )
                  )}
                </Stack>
                <Stack>
                  <Typography component={"h4"} className="over-view-data">
                    {user?.data?.list?.reduce(
                      (total, item) => total + item.count,
                      0
                    )}
                  </Typography>
                  {user?.data?.list?.map(
                    (item, index) =>
                      item._id !== null && (
                        <Typography
                          key={index}
                          component={"h4"}
                          className="over-view-label"
                        >
                          {item.count}
                        </Typography>
                      )
                  )}
                </Stack>
              </Stack>
            </Paper>
            <Paper square className="over-view">
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack>
                  <Typography variant="body2" className="over-view-data">
                    Staff
                  </Typography>
                  {staff?.data?.list?.map(
                    (item, index) =>
                      item._id !== null && (
                        <Typography
                          key={index}
                          variant="body2"
                          className="over-view-label"
                        >
                          {item._id || "Unknown"}
                        </Typography>
                      )
                  )}
                </Stack>
                <Stack>
                  <Typography component={"h4"} className="over-view-data">
                    {staff?.data?.list?.reduce(
                      (total, item) => total + item.count,
                      0
                    )}
                  </Typography>
                  {staff?.data?.list?.map(
                    (item, index) =>
                      item._id !== null && (
                        <Typography
                          key={index}
                          component={"h4"}
                          className="over-view-label"
                        >
                          {item.count}
                        </Typography>
                      )
                  )}
                </Stack>
              </Stack>
            </Paper>
            <Paper square className="over-view">
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack>
                  <Typography variant="body2" className="over-view-data">
                    Events
                  </Typography>
                  <Typography variant="body2" className="over-view-label">
                    Templates
                  </Typography>
                  <Typography variant="body2" className="over-view-label">
                    Frames
                  </Typography>
                </Stack>
                <Stack>
                  <Typography component={"h4"} className="over-view-data">
                    {events?.data?.obj?.countTotalEvent}
                  </Typography>
                  <Typography component={"h4"} className="over-view-label">
                    {events?.data?.obj?.countTotalPhotos}
                  </Typography>
                  <Typography component={"h4"} className="over-view-label">
                    {events?.data?.obj?.countTotalFrames}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
            <Paper square className="over-view">
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack>
                  <Typography variant="body2" className="over-view-data">
                    Business
                  </Typography>
                  <Typography variant="body2" className="over-view-label">
                    Misc
                  </Typography>
                  <Typography variant="body2" className="over-view-label">
                    Political
                  </Typography>
                </Stack>
                <Stack>
                  <Typography component={"h4"} className="over-view-data">
                    {business?.data?.obj?.countTotalBusiness}
                  </Typography>
                  <Typography component={"h4"} className="over-view-label">
                    {business?.data?.obj?.countMiscBusiness}
                  </Typography>
                  <Typography component={"h4"} className="over-view-label">
                    {business?.data?.obj?.countTotalPolitical}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
          {/* <MultiSelect/> */}
        </Stack>
      </StyledWrapper>
    </>
  );
};

export default Dashboard;
const StyledWrapper = styled(Box)`
  padding: 20px 30px;
  box-sizing: border-box;
  max-width: 100%;
  .label {
    color: #020c0a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    padding: 10px 0;
    margin: 10px 0;
    opacity: 0.75;
    outline: none;
  }
  .over-view {
    padding: 19.6px;
    width: 180.595px;
    height: 120.394px;
    box-sizing: border-box;
    flex-shrink: 0;
    padding-top: 15px;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.1);
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
  }
  .over-view-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .over-view-data {
    color: #020c0a;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 5px;
  }
  .send-reminder {
    border-radius: 10px;
    border: 1px solid #1a5346;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
    width: 184.639px;
    height: 50px;
    flex-shrink: 0;
    color: #1a5346;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 9px 16px;
  }
  table {
    border-radius: 10px;
    overflow: hidden;
  }
  .over-view-data-title {
    color: #020c0a;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 20px 0;
  }
  .filter-button {
    width: 122.823px;
    height: 36.062px;
    flex-shrink: 0;
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    border-radius: 10px;
    background: #1a5346;
    text-transform: capitalize;
  }
  .export-label {
    color: #020c0a;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
  }
`;
