import React, { useEffect, useState } from "react";
import CommonDialog from "../CommonDialog";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  styled,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import TextFieldLabel from "../../common/TextFieldLabel";
import ControllerInput from "../../common/ControllerInput";
import ControllerDatePicker from "../../common/ControllerDatePicker";
import ControllerSelect from "../../common/ControllerSelect";
import { useMutation, useQuery } from "react-query";
import {
  getDistrictListByStateName,
  getStateList,
} from "../../services/misc.service";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { emailRegex } from "../../utils/constants";
import {
  addStaffProfile,
  getDesignationDropdown,
  updateStaffProfile,
} from "../../services/staff.service";
import { onError } from "../../utils/helpers";
import uploadFile from "../../utils/uploadFile";
import {
  AddSaffProfilePostProps,
  StaffListColumnProps,
} from "../../types/staff";
import { LoadingButton } from "@mui/lab";
import { CloudUpload } from "@mui/icons-material";
import dayjs from "dayjs";
type DistrictListProps = {
  value: string | number;
  label: string;
};
const initialValue: AddSaffProfilePostProps = {
  mobileNumber: "",
  whatsAppNumber: "",
  AADHAR: "",
  designation: "",
  otherDesignation: undefined,
  DOB: null,
  address: {
    address: "",
    state: "",
    dist: "",
    pinCode: "",
  },
  email: "",
  fatherInfo: {
    firstName: "",
    middleName: "",
    lastName: "",
  },
  firstName: "",
  gender: "",
  lastName: "",
  middleName: "",
  profilePic: "",
};
type Props = {
  editData?: StaffListColumnProps | null;
  open: boolean;
  onClose: () => void;
  refetch: () => void;
};
const AddNewStaffDialog: React.FC<Props> = ({
  open = false,
  onClose,
  editData,
  refetch,
}) => {
  const [state, setState] = useState<{
    districtList: DistrictListProps[];
    profileFile: File | null;
    profile: string;
    loading: boolean;
  }>({ districtList: [], profileFile: null, profile: "", loading: false });
  const { control, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: initialValue,
  });
  const stateName = watch("address.state");
  const designation = watch("designation");
  const handleCloseDialog = () => {
    setState((prev) => ({
      ...prev,
      districtList: [],
      profileFile: null,
      profile: "",
      loading: false,
    }));
    reset(initialValue);
    onClose();
  };
  const handleUploadProfile: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      setState((prev: any) => ({
        ...prev,
        profile: URL.createObjectURL(file),
        profileFile: file,
      }));
    }
  };
  const onSubmit: SubmitHandler<AddSaffProfilePostProps> = async (data) => {
    const body = data;
    body.profilePic = state.profile;
    if (body.designation === "Other") {
      body.designation = body?.otherDesignation ?? "";
    }
    delete body.otherDesignation;
    setState((prev) => ({ ...prev, loading: true }));
    if (state.profileFile) {
      try {
        let s3FilePath = `postAndShare/staff/${Date.now()}/${
          state.profileFile.name
        }`;
        let { fileURL } = await uploadFile(
          state.profileFile,
          s3FilePath,
          state.profileFile?.type
        );
        body.profilePic = fileURL ? fileURL : "";
      } catch (error) {
        console.log(error);
      }
    }
    if (editData) {
      const updateBodyData = { ...body };
      delete updateBodyData?.designation;
      delete updateBodyData?.mobileNumber;
      updateStaffProfileMutate({
        ...updateBodyData,
        staffDocId: editData?._id,
      });
    } else {
      addStaffProfileMutate(body);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };
  const { data: getStateListData } = useQuery({
    queryKey: ["getStateList"],
    queryFn: getStateList,
    onError: (err: AxiosError<{ message: string }>) => {
      toast.error(err.response?.data.message || "Something went wrong");
    },
  });
  const { data: getDesignationDropdownData } = useQuery({
    queryKey: ["getDesignationDropdown"],
    queryFn: getDesignationDropdown,
    onError: (err: AxiosError<{ message: string }>) => {
      toast.error(err.response?.data.message || "Something went wrong");
    },
  });

  useQuery({
    queryKey: ["getDistrictListByStateName", stateName],
    queryFn: () => getDistrictListByStateName({ stateName }),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        districtList: success?.data?.districtList?.map((res: string) => ({
          value: res,
          label: res,
        })),
      }));
    },
    onError: (err: AxiosError<{ message: string }>) => {
      toast.error(err.response?.data.message || "Something went wrong");
    },
    enabled: stateName ? true : false,
  });
  const { isLoading: addStaffProfileLoading, mutate: addStaffProfileMutate } =
    useMutation({
      mutationKey: ["addStaffProfile"],
      mutationFn: addStaffProfile,
      onSuccess: (success) => {
        handleCloseDialog();
        toast.success(success?.data?.message);
        refetch();
      },
      onError: onError,
    });
  const {
    isLoading: updateStaffProfileLoading,
    mutate: updateStaffProfileMutate,
  } = useMutation({
    mutationKey: ["updateStaffProfile"],
    mutationFn: updateStaffProfile,
    onSuccess: (success) => {
      handleCloseDialog();
      toast.success(success?.data?.message);
      refetch();
    },
    onError: onError,
  });
  useEffect(() => {
    if (stateName && !editData) {
      setValue("address.dist", "");
    }
  }, [stateName]);
  useEffect(() => {
    if (editData) {
      reset({
        mobileNumber: editData?.mobileNumber,
        whatsAppNumber: editData?.whatsAppNumber,
        AADHAR: editData?.AADHAR,
        designation: editData?.designation,
        otherDesignation: undefined,
        DOB: dayjs(editData?.DOB),
        address: {
          address: editData?.address?.address,
          state: editData?.address?.state,
          dist: editData?.address?.dist,
          pinCode: editData?.address?.pinCode,
        },
        email: editData?.email,
        fatherInfo: editData?.fatherInfo,
        firstName: editData?.firstName,
        gender: editData?.gender,
        lastName: editData?.lastName,
        middleName: editData?.middleName,
        profilePic: editData?.profilePic,
      });
      setState((prev) => ({ ...prev, profile: editData?.profilePic }));
    }
  }, [editData]);

  return (
    <div>
      <CommonDialog
        minWidth="800px"
        open={open}
        onClose={handleCloseDialog}
        title={editData ? "Update Staff" : "Add New Staff"}
      >
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} id="add_new_staff">
            <Grid container spacing={1}>
              <Grid xs={12} sm={4}>
                <Stack direction={"column"} alignItems={"center"}>
                  <div>
                    <UploadImage
                      src={
                        state.profile
                          ? state.profile
                          : "/image-placeholder.jpeg"
                      }
                      alt="flag"
                    />
                  </div>
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUpload />}
                  >
                    Upload Photo
                    <input
                      hidden
                      type="file"
                      onChange={handleUploadProfile}
                      accept="image/*"
                    />
                  </Button>
                </Stack>
              </Grid>
              <Grid item container sm={8} spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title={"Firstname"} required />
                  <ControllerInput
                    control={control}
                    rules={{
                      required: "First Name required",
                    }}
                    name="firstName"
                    placeholder="Type Firstname"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title={"Middle Name"} />
                  <ControllerInput
                    control={control}
                    name="middleName"
                    placeholder="Type middlename"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title={"Last Name"} />
                  <ControllerInput
                    control={control}
                    name="lastName"
                    placeholder="Type Lastname"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title={"Father Name"} required />
                  <ControllerInput
                    rules={{
                      required: "Father name required",
                    }}
                    control={control}
                    name="fatherInfo.firstName"
                    placeholder="Type fathername"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextFieldLabel title={"Mobile Number"} required />
                <ControllerInput
                  disabled={editData ? true : false}
                  type="number"
                  rules={{
                    required: "Mobile number required",
                    minLength: {
                      value: 10,
                      message: "Number Should be 10 digit",
                    },
                  }}
                  control={control}
                  name="mobileNumber"
                  placeholder="Type fathername"
                  numberLenght={10}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextFieldLabel title={"WhatsApp Number"} required />
                <ControllerInput
                  type="number"
                  rules={{
                    required: "WhatsApp number required",
                    minLength: {
                      value: 10,
                      message: "Number Should be 10 digit",
                    },
                  }}
                  control={control}
                  name="whatsAppNumber"
                  placeholder="Type WhatsApp Number"
                  numberLenght={10}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextFieldLabel title={"Aadhar Number"} />
                <ControllerInput
                  type="number"
                  rules={{
                    minLength: {
                      value: 12,
                      message: "Aadhar Number Should be 12 digit",
                    },
                  }}
                  control={control}
                  name="AADHAR"
                  placeholder="Type Aadhar number"
                  numberLenght={12}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextFieldLabel title={"Email"} />
                <ControllerInput
                  type="email"
                  rules={{
                    pattern: {
                      value: emailRegex,
                      message: "Email not valid",
                    },
                  }}
                  control={control}
                  name="email"
                  placeholder="Type email"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextFieldLabel title={"Date of Birth"} required />
                <ControllerDatePicker
                  rules={{
                    required: "DOB required",
                  }}
                  control={control}
                  name="DOB"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextFieldLabel title={"Gender"} required />
                <ControllerSelect
                  rules={{
                    required: "Gender required",
                  }}
                  control={control}
                  name="gender"
                  options={[
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextFieldLabel title={"Designation"} required />
                <ControllerSelect
                  disabled={editData ? true : false}
                  rules={{
                    required: "Designation required",
                  }}
                  control={control}
                  name="designation"
                  options={
                    !getDesignationDropdownData?.data?.list
                      ? [{ value: "other", label: "Other" }]
                      : [
                          ...getDesignationDropdownData?.data?.list?.map(
                            (res: string) => ({ value: res, label: res })
                          ),
                          { value: "other", label: "Other" },
                        ]
                  }
                />
              </Grid>
              {designation === "other" && (
                <Grid item xs={12} sm={6} md={4}>
                  <TextFieldLabel title={"Designation Name"} required />
                  <ControllerInput
                    type="text"
                    rules={{
                      required: "Desgination name required",
                    }}
                    control={control}
                    name="otherDesignation"
                    placeholder="Type designation name"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextFieldLabel title={"Address"} required />
                <ControllerInput
                  rules={{
                    required: "Address required",
                  }}
                  control={control}
                  name="address.address"
                  placeholder="Type Address"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextFieldLabel title={"State"} required />
                <ControllerSelect
                  rules={{
                    required: "State required",
                  }}
                  control={control}
                  name="address.state"
                  options={getStateListData?.data?.stateList?.map(
                    (res: string) => ({
                      label: res,
                      value: res,
                    })
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextFieldLabel title={"District"} required />
                <ControllerSelect
                  rules={{
                    required: "District required",
                  }}
                  control={control}
                  name="address.dist"
                  options={state.districtList}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextFieldLabel title={"Pin code"} required />
                <ControllerInput
                  rules={{
                    required: "Pin Code required",
                    minLength: {
                      value: 6,
                      message: "Pin code should be 6 digit",
                    },
                  }}
                  control={control}
                  name="address.pinCode"
                  placeholder="Type pin code"
                  numberLenght={6}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            sx={{ width: "200px" }}
            variant="contained"
            type="submit"
            form="add_new_staff"
            loading={
              addStaffProfileLoading ||
              updateStaffProfileLoading ||
              state.loading
            }
          >
            {editData ? "Update" : "Submit"}
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </div>
  );
};

export default AddNewStaffDialog;
const UploadImage = styled("img")`
  height: 100px;
  width: 100px;
  object-fit: contain;
`;
