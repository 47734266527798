import React from "react";
import Fallback from "../../common/Fallback";

const RegisteredBusinessProfile = React.lazy(
  () => import("./RegisteredBusinessProfile")
);
const BusiessCategory = React.lazy(() => import("./BusiessCategory"));
const BusiessSubCategory = React.lazy(() => import("./BusiessSubCategory"));
const Business = React.lazy(() => import("./Business"));

export const BusinessPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Business {...props} />
  </React.Suspense>
);

export const BusiessCategoryPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <BusiessCategory {...props} />
  </React.Suspense>
);
export const BusiessSubCategoryPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <BusiessSubCategory {...props} />
  </React.Suspense>
);
export const RegisteredBusinessProfilePage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <RegisteredBusinessProfile {...props} />
  </React.Suspense>
);
