import React, { useState } from "react";
import {
  Box,
  DialogContent,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
} from "@mui/material";
import AppliedFiltersWrapper from "../../components/common/AppliedFiltersWrapper";
import AppliedFilterChip from "../../components/common/AppliedFilterChip";
import CommonFilterDialog from "../../components/common/CommonFilterDialog";
import FilterCheckbox from "../../components/common/FilterCheckbox";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  CustomSelectBox,
  CustomTextField,
} from "../../common/globalStyledComponent";
import { useQuery } from "react-query";
import {
  getDistrictListByStateName,
  getStateList,
} from "../../services/misc.service";
import { onError } from "../../utils/helpers";
import { GetUserParamsProps } from "../../types/user";
type Props = {
  onClickFilter: (res: GetUserParamsProps) => void;
};
const UserFilter: React.FC<Props> = ({ onClickFilter }) => {
  const [state, setState] = useState({
    dialog: false,
    name: "",
    appStatus: "",
    createdFrom: null,
    createdTo: null,
    ageFrom: "",
    ageTo: "",
    status: "",
    gender: "",
    state: "",
    district: "",
  });

  const [checkboxes, setCheckboxes] = useState({
    byName: false,
    bydate: false,
    byStatus: false,
    byAppStatus: false,
    byGender: false,
    byAge: false,
    byState: false,
    byDistrict: false,
  });

  const handleCloseFilterDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleView = () => {
    onClickFilter({
      ...(checkboxes.byName && {
        name: state.name,
      }),
      ...(checkboxes.bydate && {
        from: state.createdFrom,
        to: state.createdTo,
      }),
      ...(checkboxes.byStatus && {
        status: state.status,
      }),
      ...(checkboxes.byAppStatus && {
        appStatus: state.appStatus,
      }),
      ...(checkboxes.byGender && {
        gender: state.gender,
      }),
      ...(checkboxes.byAge && {
        ageFrom: state.ageFrom,
        ageTo: state.ageTo,
      }),
      ...(checkboxes.byState && {
        state: state.state,
      }),
      ...(checkboxes.byDistrict && {
        dist: state.district,
      }),
    });
    handleCloseFilterDialog();
  };
  const { data: getStateListData, isLoading: getStateListLoading } = useQuery({
    queryKey: ["getStateList", checkboxes.byState],
    queryFn: getStateList,
    onError: onError,
    enabled: checkboxes.byState ? true : false,
  });
  const { data: getDistrictListData, isLoading: getDistrictListLoading } =
    useQuery({
      queryKey: ["getDistrictList", state.state, checkboxes.byDistrict],
      queryFn: () => getDistrictListByStateName({ stateName: state.state }),
      onError: onError,
      enabled: checkboxes.byDistrict && state.state ? true : false,
    });
  return (
    <>
      <AppliedFiltersWrapper
        onClick={() => setState((prev) => ({ ...prev, dialog: true }))}
        count={Object.values(checkboxes).filter((res) => res).length}
      >
        {checkboxes.byName && (
          <AppliedFilterChip label={"Name"} value={state.name ?? "''"} />
        )}
        {checkboxes.bydate && (
          <>
            <AppliedFilterChip
              label={"Crate From"}
              value={
                state.createdFrom
                  ? dayjs(state.createdFrom).format("DD MMM, YYYY")
                  : "''"
              }
            />
            <AppliedFilterChip
              label={"Create To"}
              value={
                state.createdTo
                  ? dayjs(state.createdTo).format("DD MMM, YYYY")
                  : "''"
              }
            />
          </>
        )}
        {checkboxes.byAge && (
          <>
            <AppliedFilterChip
              label={"Age From"}
              value={state.ageFrom ? state.ageFrom : "''"}
            />
            <AppliedFilterChip
              label={"Age To"}
              value={state.ageTo ? state.ageTo : "''"}
            />
          </>
        )}
        {checkboxes.byGender && (
          <AppliedFilterChip label={"Gender"} value={state.gender ?? "''"} />
        )}
        {checkboxes.byStatus && (
          <AppliedFilterChip label={"Status"} value={state.status ?? "''"} />
        )}
        {checkboxes.byAppStatus && (
          <AppliedFilterChip
            label={"App Status"}
            value={state.appStatus ?? "''"}
          />
        )}
        {checkboxes.byState && (
          <AppliedFilterChip label={"State"} value={state.state ?? "''"} />
        )}
        {checkboxes.byDistrict && (
          <AppliedFilterChip
            label={"District"}
            value={state.district ?? "''"}
          />
        )}
      </AppliedFiltersWrapper>

      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseFilterDialog}
        handleSaveAndView={handleView}
        width="500px"
      >
        {(getStateListLoading || getDistrictListLoading) && <LinearProgress />}
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <FilterCheckbox
                label="By Name"
                checked={checkboxes.byName}
                onChange={(e) => {
                  setCheckboxes((prev) => ({ ...prev, byName: !prev.byName }));
                  if (checkboxes.byName)
                    setState((prev: any) => ({
                      ...prev,
                      instituteDocIdList: [],
                      instituteName: "",
                    }));
                }}
              />
              {/* {checkboxes.byName && (
                <InstituteNameSearcInput handleClick={handleClickSearchValue} />
              )} */}
            </Grid>
            <Grid item xs={12}>
              <FilterCheckbox
                label="By Date"
                checked={checkboxes.bydate}
                onChange={(e) =>
                  setCheckboxes((prev) => ({ ...prev, bydate: !prev.bydate }))
                }
              />
              {checkboxes.bydate && (
                <Stack direction={"row"} gap={1}>
                  <Box flex={1}>
                    <DatePicker
                      value={state.createdFrom}
                      onChange={(date) =>
                        setState((prev: any) => ({
                          ...prev,
                          createdFrom: date,
                        }))
                      }
                      format="DD/MM/YYYY"
                    />
                  </Box>
                  <Box flex={1}>
                    <DatePicker
                      value={state.createdTo}
                      onChange={(date) =>
                        setState((prev: any) => ({ ...prev, createdTo: date }))
                      }
                      format="DD/MM/YYYY"
                    />
                  </Box>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              <FilterCheckbox
                label="By Age"
                checked={checkboxes.byAge}
                onChange={(e) =>
                  setCheckboxes((prev) => ({ ...prev, byAge: !prev.byAge }))
                }
              />
              {checkboxes.byAge && (
                <Stack direction={"row"} gap={1}>
                  <Box flex={1}>
                    <CustomTextField
                      placeholder="Type age from"
                      value={state.ageFrom}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          ageFrom: e.target.value,
                        }))
                      }
                      type="number"
                    />
                  </Box>
                  <Box flex={1}>
                    <CustomTextField
                      placeholder="Type age to"
                      value={state.ageTo}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          ageTo: e.target.value,
                        }))
                      }
                      type="number"
                    />
                  </Box>
                </Stack>
              )}
            </Grid>

            <Grid item xs={12}>
              <FilterCheckbox
                label="By Gender"
                checked={checkboxes.byGender}
                onChange={(e) =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    byGender: !prev.byGender,
                  }))
                }
              />
              {checkboxes.byGender && (
                <CustomSelectBox
                  value={state.gender}
                  onChange={(e) =>
                    setState((prev: any) => ({
                      ...prev,
                      gender: e.target.value,
                    }))
                  }
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </CustomSelectBox>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterCheckbox
                label="By State"
                checked={checkboxes.byState}
                onChange={(e) =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    byState: !prev.byState,
                  }))
                }
              />
              {checkboxes.byState && (
                <CustomSelectBox
                  native
                  value={state.state}
                  onChange={(e) =>
                    setState((prev: any) => ({
                      ...prev,
                      state: e.target.value,
                      district: "",
                    }))
                  }
                >
                  <MenuItem value="" component="option">
                    Select
                  </MenuItem>
                  {getStateListData?.data?.stateList?.map((res: string) => (
                    <MenuItem value={res} component="option">
                      {res}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterCheckbox
                label="By District"
                checked={checkboxes.byDistrict}
                onChange={(e) =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    byDistrict: !prev.byDistrict,
                  }))
                }
              />
              {checkboxes.byDistrict && (
                <CustomSelectBox
                  native
                  value={state.district}
                  onChange={(e) =>
                    setState((prev: any) => ({
                      ...prev,

                      district: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="" component="option">
                    Select
                  </MenuItem>
                  {getDistrictListData?.data?.districtList?.map(
                    (res: string) => (
                      <MenuItem value={res} component="option">
                        {res}
                      </MenuItem>
                    )
                  )}
                </CustomSelectBox>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterCheckbox
                label="By Status"
                checked={checkboxes.byStatus}
                onChange={(e) =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    byStatus: !prev.byStatus,
                  }))
                }
              />
              {checkboxes.byStatus && (
                <CustomSelectBox
                  value={state.status}
                  onChange={(e) =>
                    setState((prev: any) => ({
                      ...prev,
                      status: e.target.value,
                    }))
                  }
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inActive">In-Active</MenuItem>
                </CustomSelectBox>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterCheckbox
                label="By App Status"
                checked={checkboxes.byAppStatus}
                onChange={(e) =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    byAppStatus: !prev.byAppStatus,
                  }))
                }
              />
              {checkboxes.byAppStatus && (
                <CustomSelectBox
                  value={state.appStatus}
                  onChange={(e) =>
                    setState((prev: any) => ({
                      ...prev,
                      appStatus: e.target.value,
                    }))
                  }
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  <MenuItem value="installed">Installed</MenuItem>
                  <MenuItem value="uninstalled">UnInstalled</MenuItem>
                </CustomSelectBox>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </CommonFilterDialog>
    </>
  );
};

export default UserFilter;
