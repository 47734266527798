import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../layout";
import { PATH_CONSTANTS } from "../utils/constants";
import Dashboard from "../pages/dashboard/Dashboard";
import StaffList from "../pages/staffList/StaffList";
import UserList from "../pages/userList/UserList";
import Calendar from "../pages/calendar/Calendar";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import AdminProtectedRoute from "./AdminProtectedRoute";
import AuthProtectedRoute from "./AuthProtectedRoute";
import Upload from "../pages/uploads/Upload";
import { PoliticalPage } from "../pages/political";
import { BusinessPage } from "../pages/business";
import { CategoryPage } from "../pages/category";
import RouteNotFound from "../components/common/404";
import { PhotoPage } from "../pages/photo";
import { EventPage } from "../pages/event";
import { ContentPage } from "../pages/content";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import TermAndCondition from "../pages/term-and-condition/TermAndCondition";
import RefundPolicy from "../pages/refund-policy/RefundPolicy";

export const MainRoute = () => {
  return (
    <Routes>
      <Route element={<RouteNotFound />} path="*" />
      <Route element={<PrivacyPolicy />} path="/privacy-policy" />
      <Route element={<TermAndCondition />} path="/term-and-condition" />
      <Route element={<RefundPolicy />} path="/refund-policy" />

      <Route
        element={
          <AuthProtectedRoute>
            <Login />
          </AuthProtectedRoute>
        }
        path={PATH_CONSTANTS.login}
      />
      <Route
        element={
          <AuthProtectedRoute>
            <Register />
          </AuthProtectedRoute>
        }
        path={PATH_CONSTANTS.register}
      />
      <Route
        element={
          <AdminProtectedRoute>
            <Layout />
          </AdminProtectedRoute>
        }
        path={PATH_CONSTANTS.dashboard}
      >
        <Route element={<Dashboard />} path={PATH_CONSTANTS.dashboard} />
        <Route element={<StaffList />} path={PATH_CONSTANTS.staffList} />
        <Route element={<UserList />} path={PATH_CONSTANTS.userList} />
        <Route element={<PoliticalPage />} path={PATH_CONSTANTS.political} />
        {/* <Route element={<BusinessPage />} path={PATH_CONSTANTS.business} /> */}
        <Route element={<CategoryPage />} path={PATH_CONSTANTS.category} />
        <Route element={<EventPage />} path={PATH_CONSTANTS.event} />
        <Route element={<ContentPage />} path={PATH_CONSTANTS.content} />
        <Route element={<Upload />} path={PATH_CONSTANTS.upload} />
        <Route element={<PhotoPage />} path={PATH_CONSTANTS.photo} />
      </Route>
    </Routes>
  );
};
