import {
  AddEventPostProps,
  GetEventParamsProps,
  UpdateEventPostProps,
} from "../types/event";
import { axiosInstance } from "./axios";

const BASE_URL = process.env.REACT_APP_BASE;
const ORG_EVENT_URL = `${BASE_URL}/admin/orgEvent`;

export const getPhotosByEventDocId = (eventDocId: string) => {
  return axiosInstance.get(`${ORG_EVENT_URL}/getPhotosByEventDocId`, {
    params: { eventDocId },
  });
};
export const getOrgEvent = (params: GetEventParamsProps) => {
  return axiosInstance.get(`${ORG_EVENT_URL}/getOrgEvent`, { params });
};
export const getOrgEventTableView = (params: GetEventParamsProps) => {
  return axiosInstance.get(`${ORG_EVENT_URL}/getOrgEventTableView`, { params });
};
export const addOrgEvent = (body: AddEventPostProps) => {
  return axiosInstance.post(`${ORG_EVENT_URL}/addOrgEvent`, body);
};
export const updateEvent = (body: UpdateEventPostProps) => {
  return axiosInstance.put(`${ORG_EVENT_URL}/updateOrgEvent`, body);
};
export const deleteEvent = (eventDocId: string) => {
  return axiosInstance.delete(`${ORG_EVENT_URL}/deleteOrgEvent/${eventDocId}`);
};
