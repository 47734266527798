import React from "react";
import Fallback from "../../common/Fallback";

const TableView = React.lazy(() => import("./TableView"));
const Event = React.lazy(() => import("./Event"));
const Calendar = React.lazy(() => import("./Calendar"));
export const TableViewPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <TableView {...props} />
  </React.Suspense>
);
export const EventPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Event {...props} />
  </React.Suspense>
);
export const CalendarPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Calendar {...props} />
  </React.Suspense>
);
