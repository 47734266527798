import React from "react";
import CommonDialog from "../CommonDialog";
import { DialogContent, Tab } from "@mui/material";
import PhotoForm from "../../components/upload/forms/PhotoFrom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TabsWrapper } from "../../common/globalStyledComponent";
type Props = {
  open: boolean;
  onClose: () => void;
  refetch?: () => void;
};
const AddPhotoDialog: React.FC<Props> = ({ open, onClose, refetch }) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, value: any) => {
    setValue(value);
  };
  const handleCloseDialog = () => {
    onClose();
  };
  return (
    <CommonDialog open={open} onClose={handleCloseDialog} title="Add Photo">
      <DialogContent>
        <TabContext value={value}>
          <TabsWrapper sx={{ mx: 1 }}>
            <TabList onChange={handleChange}>
              <Tab label="General" value="1" />
              <Tab label="Event" value="2" />
              <Tab label="Political" value="3" />
              <Tab label="Business" value="4" />
            </TabList>
          </TabsWrapper>
          <TabPanel value="1" sx={{ p: "0 !important" }}></TabPanel>
          <TabPanel value="2" sx={{ p: "0 !important" }}></TabPanel>
          <TabPanel value="3" sx={{ p: "0 !important" }}></TabPanel>
          <TabPanel value="4" sx={{ p: "0 !important" }}></TabPanel>
        </TabContext>
      </DialogContent>
    </CommonDialog>
  );
};

export default AddPhotoDialog;
