import {
  Box,
  Container,
  LinearProgress,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";
import CommonTable1 from "../../common/CommanTable";
import { useQuery } from "react-query";
import { getUserList } from "../../services/staff.service";
import formatDate from "../../common/Formater";

import ExportButtons from "../../common/ExportButtons";
import UserFilter from "../../dialogs/filters/UserFilter";
import { FormatFullName, onError } from "../../utils/helpers";
import { Helmet } from "react-helmet-async";
const UserList = () => {
  const [data, setData] = useState({ list: [], pages: 1 });
  const [state, setState] = useState({
    fetch: false,
    filteredData: null,
    page: 1,
  });
  const handleOnclickPdf = () => {};
  const handleOnclickExcel = () => {};
  const handleOnClickFilter = (filteredData) => {
    setState((prev) => ({ ...prev, filteredData, fetch: !prev.fetch }));
  };
  const columns = useMemo(
    () => [
      {
        header: "Sr. No.",
        Cell: ({ cell }) => {
          return (
            <div>
              {cell.row.index + 1 > 9
                ? cell.row.index + 1
                : `0${cell.row.index + 1}`}
            </div>
          );
        },
        size: 10,
      },
      {
        accessorKey: "firstName",
        header: "User Name",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {FormatFullName(
              original?.firstName,
              original?.middleName,
              original?.lastName
            )}
          </Typography>
        ),
      },
      {
        accessorKey: "currentAddress.state",
        header: "State",
        size: 50,
      },
      {
        accessorKey: "currentAddress.dist",
        header: "Dist.",
        size: 50,
      },
      {
        accessorKey: "mobileNumber",
        header: "Mobile Number",
        size: 60,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 40,
      },
      {
        accessorKey: "appStatus",
        header: "App Status",
        size: 60,
      },
      {
        accessorKey: "accountType",
        header: "Acc. Type",
        size: 50,
      },

      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 50,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          return <div>{formatDate(value)}</div>;
        },
      },
    ],
    []
  );

  // Getting User List Data
  const { isLoading: getUserListLoading, isFetching: getUserListFetching } =
    useQuery({
      queryKey: ["user_list_data", state.fetch, state.page],
      queryFn: () =>
        getUserList(
          state.filteredData
            ? { page: state.page, ...state.filteredData }
            : { page: state.page }
        ),
      onSuccess: (success) => {
        setData((prev) => ({
          ...prev,
          list: success?.data?.list,
          ...success?.data,
        }));
      },
      onError: onError,
    });

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>

      <Container maxWidth="xl">
        <StyledWrapper>
          <Stack direction={"row"} alignItems={"center"} my={1}>
            <Stack flexGrow={1} direction={"row"} gap={2}>
              <UserFilter onClickFilter={handleOnClickFilter} />
            </Stack>
            <Stack>
              <ExportButtons
                onClickExcel={handleOnclickExcel}
                onClickPdf={handleOnclickPdf}
              />
            </Stack>
          </Stack>
          {(getUserListLoading || getUserListFetching) && <LinearProgress />}
          <CommonTable1 columns={columns} data={data?.list} maxHeight="70vh" />
          <Stack direction={"row"} justifyContent={"flex-end"} my={1}>
            <Pagination
              page={state.page}
              count={data?.pages}
              shape="rounded"
              onChange={(e, value) =>
                setState((prev) => ({ ...prev, page: value }))
              }
            />
          </Stack>
        </StyledWrapper>
      </Container>
    </>
  );
};

export default UserList;

const StyledWrapper = styled(Box)`
  margin-top: 10px;

  .label {
    color: #020c0a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    padding: 10px 0;
    margin: 10px 0;
    opacity: 0.75;
    outline: none;
  }

  .over-view {
    padding: 19.6px;
    width: 166.595px;
    height: 80.394px;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.1);
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
  }

  .over-view-label {
    color: #020c0a;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.5;
  }

  .over-view-data {
    color: #020c0a;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .send-reminder {
    border-radius: 10px;
    border: 1px solid #1a5346;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
    width: 184.639px;
    height: 50px;
    flex-shrink: 0;
    color: #1a5346;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 9px 16px;
  }

  .over-view-data-title {
    color: #020c0a;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 20px 0;
  }

  .filter-button {
    width: 122.823px;
    height: 36.062px;
    flex-shrink: 0;
    color: #fff;
    text-align: center;

    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    border-radius: 10px;
    background: #1a5346;
    text-transform: capitalize;
  }

  .export-label {
    color: #020c0a;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
  }
`;
