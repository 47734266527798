import { useMutation } from "react-query";
import { addFrame } from "../../../services/frame.service";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import uploadFile from "../../../utils/uploadFile";
import { toast } from "react-toastify";
import { Box, CircularProgress, Stack } from "@mui/material";
import PreviewDownloadImage from "../../../common/PreviewDownloadImage";
import { LoadingButton } from "@mui/lab";
import CommonInputSmall from "../../../common/CommonInputSmall";

const FrameForm = ({ refetch, handleClose }) => {
  const initialValues = {
    themeColor: "",
    width: "",
    height: "",
    sizeFor: "",
    framePic: "",
    tags: ["holi", "diwali"],
  };

  /// Add Frame Mutation
  const { mutate, isLoading } = useMutation(addFrame, {
    onSuccess: (success) => {
      refetch();
      handleClose();
      toast.success(success?.data?.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues,
    onSubmit: (formData, action) => {
      mutate(formData);
      //action.resetForm();
    },
  });
  const [loading, setLoading] = useState(false);
  // const handleUpload = async (e) => {
  //   console.log("attachmentUrl");

  //   setLoading(true);
  //   try {
  //     let s3FilePath = `frame/${new Date()}/${e.target.files[0].name}`;
  //     let attachmentUrl = await uploadFile(
  //       e.target.files[0],
  //       s3FilePath,
  //       e.target.files[0].type
  //     );

  //     setValues((prev) => ({ ...prev, framePic: attachmentUrl.fileURL }));
  //     console.log("attachmentUrl", attachmentUrl);
  //     console.log("values.framePic", values.framePic);
  //     setLoading(false);
  //   } catch (error) {
  //     toast.error(error);
  //     setLoading(false);
  //   }
  // };
  const userDetail = useSelector((store) => store.auth.userDetail);
  let userDocId = userDetail?._id;

  const handleUpload = async (e) => {
    setLoading(true);

    try {
      // let s3FilePath = `notice/${selectedSchoolId}/${e.target.files[0].name}`;
      let s3FilePath = `frame/${userDocId}/${e.target.files[0].name}`;
      let attachmentUrl = await uploadFile(
        e.target.files[0],
        s3FilePath,
        e.target.files[0].type
      );
      setValues((prev) => ({ ...prev, framePic: attachmentUrl.fileURL }));
      console.log("attachmentUrl", attachmentUrl);
      console.log("values.framePic", values.framePic);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const handleDeleteImage = (img) => {
    setValues((prev) => ({ ...prev, framePic: "" }));
  };

  // const handleClick = () => {
  //   console.log("hiiiiiiiiiiii")
  // }
  //// Ading Frame is scompleted

  return (
    <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
      {/* uPLOd Image start */}
      {values.framePic ? (
        <PreviewDownloadImage
          src={values.framePic}
          deleteIcon={true}
          handleDelete={() => handleDeleteImage(values.framePic)}
        />
      ) : (
        <LoadingButton
          variant="outlined"
          component="label"
          color="secondary"
          size="large"
          sx={{
            mt: 1,
            textTransform: "capitalize",
            px: 2,
            py: 0.5,
            borderRadius: "20px",
            width: "123.091px",
            height: "122.035px",
          }}
          loading={loading}
        >
          <img src={"./upload-icon.png"} alt="upload" />
          <input hidden type="file" onChange={handleUpload} accept="image/*" />
        </LoadingButton>
      )}
      {/*  UploD IMAGE END HERES */}

      {/* <Stack
        className="model-upload-button"
        sx={{
          width: "123.091px",
          height: "122.035px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
          border: "1px solid rgba(21, 70, 58, 0.20)",
          background: "rgba(90, 212, 183, 0.10)",
          cursor: "pointer",
        }}
        onClick={() => console.log("uploading image")}
      >
        <input
          hidden
          type="file"
          accept="image/*,application/pdf"
          onChange={(e) => handleUpload(e)}
        />
        <img wisth="72px" height="69px" src="./upload-icon.png" alt="upload" />
      </Stack> */}
      <Stack spacing={2} sx={{ py: 2 }} direction={"row"} justifyContent={""}>
        <Box width="50%">
          <CommonInputSmall
            id="theme-color"
            name="themeColor"
            label="Theme Color"
            type="text"
            value={values.themeColor}
            handeler={handleChange}
          />
        </Box>
        <Box width="25%">
          <CommonInputSmall
            id="sixw-width"
            name="width"
            label="Size(W)"
            type="text"
            value={values.width}
            handeler={handleChange}
          />
        </Box>
        <Box width="25%">
          <CommonInputSmall
            id="size-height"
            name="height"
            label="Size(H)"
            type="text"
            value={values.height}
            handeler={handleChange}
          />
        </Box>
      </Stack>
      <Stack spacing={2} sx={{ py: 2 }} direction={"row"} justifyContent={""}>
        <Box width="50%">
          <CommonInputSmall
            id="size-for"
            name="sizeFor"
            label="Size For"
            type="text"
            value={values.sizeFor}
            handeler={handleChange}
          />
        </Box>
        <Box width="50%">
          <CommonInputSmall
            id="tag"
            label="Tag"
            type="text"
            value={values.tags}
          />
        </Box>
      </Stack>
      <Stack direction="row" justifyContent={"flex-end"}>
        {isLoading ? (
          <CircularProgress size={30} />
        ) : (
          <LoadingButton
            type="submit"
            sx={{
              width: "177px",
              p: 1,
              borderRadius: "10px",
              background: " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
              flexShrink: 0,
            }}
            variant="contained"
            size="small"
          >
            Add Frame
          </LoadingButton>
        )}
      </Stack>
    </Box>
  );
};
export default FrameForm;
