import { createSlice } from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    userDetail: "",
    loggedInUserProfile: null,
    login_Data: null,
    schoolAuth_data: null,
    isAuthenticated: false,
  },
  reducers: {
    setLoginState: (state, action) => {
      state.login_Data = action.payload;
    },
    setLogout: (state) => {
      state.userDetail = "";
      state.loggedInUserProfile = null;
      state.login_Data = null;
      state.schoolAuth_data = null;
      state.isAuthenticated = false;
    },
    setSchoolAuthData: (state, actions) => {
      state.schoolAuth_data = actions.payload;
    },
    setUserDetails: (state, actions) => {
      state.userDetail = actions.payload;
    },
    setIsAuthenticated: (state, actions) => {
      state.isAuthenticated = actions.payload;
    },
  },
});

export default AuthSlice.reducer;
export const {
  setLoginState,
  setLogout,
  setSchoolAuthData,
  setUserDetails,
  setIsAuthenticated,
} = AuthSlice.actions;
