import { axiosInstance } from "./axios";

const BASE_URL = process.env.REACT_APP_BASE;

export const getStateList = () => {
  return axiosInstance.get(`${BASE_URL}/misc/getStateList`);
};
export const getDistrictListByStateName = (params) => {
  return axiosInstance.get(`${BASE_URL}/misc/getDistrictListByStateName`, {
    params,
  });
};
