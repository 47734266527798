import React, { useState } from "react";
import {
  Box,
  DialogContent,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
} from "@mui/material";
import AppliedFiltersWrapper from "../../components/common/AppliedFiltersWrapper";
import AppliedFilterChip from "../../components/common/AppliedFilterChip";
import CommonFilterDialog from "../../components/common/CommonFilterDialog";
import FilterCheckbox from "../../components/common/FilterCheckbox";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  CustomSelectBox,
  CustomTextField,
} from "../../common/globalStyledComponent";
import { useQuery } from "react-query";
import {
  getDistrictListByStateName,
  getStateList,
} from "../../services/misc.service";
import { FormatFullName, onError } from "../../utils/helpers";
import { getDesignationDropdown } from "../../services/staff.service";
import StaffSearch from "../../components/common/StaffSearch";
import { GetStaffParamsProps, StaffListColumnProps } from "../../types/staff";
type Props = {
  onClickFilter: (res: GetStaffParamsProps) => void;
};
const StaffFilter: React.FC<Props> = ({ onClickFilter }) => {
  const [state, setState] = useState({
    dialog: false,
    name: "",
    staffDocId: "",
    createdFrom: null,
    createdTo: null,
    desgination: "",
    gender: "",
    state: "",
    district: "",
    mobileNumber: "",
    whatsappNumber: "",
  });

  const [checkboxes, setCheckboxes] = useState({
    byName: false,
    bydate: false,
    byDesignation: false,
    byGender: false,
    byState: false,
    byDistrict: false,
    byMobileNumber: false,
    byWhatsappNumber: false,
  });

  const handleCloseFilterDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleOnclickStaffSearch = (res: StaffListColumnProps) => {
    setState((prev) => ({
      ...prev,
      name: FormatFullName(res?.firstName, res?.middleName, res?.lastName),
      staffDocId: res?._id,
    }));
  };
  const handleView = () => {
    onClickFilter({
      ...(checkboxes.byName && {
        staffDocId: state.staffDocId,
      }),
      ...(checkboxes.bydate &&
        Boolean(state.createdFrom) && {
          createdFrom: state.createdFrom,
        }),
      ...(checkboxes.bydate &&
        Boolean(state.createdTo) && {
          createdTo: state.createdTo,
        }),
      ...(checkboxes.byDesignation && {
        designation: state.desgination,
      }),
      ...(checkboxes.byGender && {
        gender: state.gender,
      }),
      ...(checkboxes.byState &&
        Boolean(state.state) && {
          state: state.state,
        }),
      ...(checkboxes.byDistrict &&
        Boolean(state.district) && {
          dist: state.district,
        }),
      ...(checkboxes.byMobileNumber && {
        mobileNumber: state.mobileNumber,
      }),
      ...(checkboxes.byWhatsappNumber && {
        whatsappNumber: state.whatsappNumber,
      }),
    });
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const { data: getStateListData, isLoading: getStateListLoading } = useQuery({
    queryKey: ["getStateList", checkboxes.byState],
    queryFn: getStateList,
    onError: onError,
    enabled: checkboxes.byState ? true : false,
  });
  const { data: getDistrictListData, isLoading: getDistrictListLoading } =
    useQuery({
      queryKey: ["getDistrictList", state.state, checkboxes.byDistrict],
      queryFn: () => getDistrictListByStateName({ stateName: state.state }),
      onError: onError,
      enabled: checkboxes.byDistrict && state.state ? true : false,
    });
  const { data: getDesignationDropdownData } = useQuery({
    queryKey: ["getDesignationDropdown", state.dialog],
    queryFn: getDesignationDropdown,
    onError: onError,
    enabled: state.dialog ? true : false,
  });
  return (
    <>
      <AppliedFiltersWrapper
        onClick={() => setState((prev) => ({ ...prev, dialog: true }))}
        count={Object.values(checkboxes).filter((res) => res).length}
      >
        {checkboxes.byName && (
          <AppliedFilterChip label={"Name"} value={state.name ?? "''"} />
        )}
        {checkboxes.byMobileNumber && (
          <AppliedFilterChip
            label={"Mobile Number"}
            value={state.mobileNumber ?? "''"}
          />
        )}
        {checkboxes.byName && (
          <AppliedFilterChip
            label={"WhatsApp Number"}
            value={state.whatsappNumber ?? "''"}
          />
        )}
        {checkboxes.bydate && (
          <>
            <AppliedFilterChip
              label={"Crate From"}
              value={
                state.createdFrom
                  ? dayjs(state.createdFrom).format("DD MMM, YYYY")
                  : "''"
              }
            />
            <AppliedFilterChip
              label={"Create To"}
              value={
                state.createdTo
                  ? dayjs(state.createdTo).format("DD MMM, YYYY")
                  : "''"
              }
            />
          </>
        )}
        {checkboxes.byGender && (
          <AppliedFilterChip label={"Gender"} value={state.gender ?? "''"} />
        )}
        {checkboxes.byDesignation && (
          <AppliedFilterChip
            label={"Designation"}
            value={state.desgination ?? "''"}
          />
        )}

        {checkboxes.byState && (
          <AppliedFilterChip label={"State"} value={state.state ?? "''"} />
        )}
        {checkboxes.byDistrict && (
          <AppliedFilterChip
            label={"District"}
            value={state.district ?? "''"}
          />
        )}
      </AppliedFiltersWrapper>

      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseFilterDialog}
        handleSaveAndView={handleView}
        width="500px"
      >
        {(getStateListLoading || getDistrictListLoading) && <LinearProgress />}
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <FilterCheckbox
                label="By Name"
                checked={checkboxes.byName}
                onChange={(e) => {
                  setCheckboxes((prev) => ({ ...prev, byName: !prev.byName }));
                }}
              />
              {checkboxes.byName && (
                <StaffSearch handleOnClick={handleOnclickStaffSearch} />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterCheckbox
                label="By Mobile Number"
                checked={checkboxes.byMobileNumber}
                onChange={(e) => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    byMobileNumber: !prev.byMobileNumber,
                  }));
                }}
              />
              {checkboxes.byMobileNumber && (
                <CustomTextField
                  placeholder="Type mobile number"
                  type="number"
                  value={state.mobileNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setState((prev) => ({
                        ...prev,
                        mobileNumber: e.target.value,
                      }));
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterCheckbox
                label="By Name"
                checked={checkboxes.byWhatsappNumber}
                onChange={(e) => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    byWhatsappNumber: !prev.byWhatsappNumber,
                  }));
                }}
              />
              {checkboxes.byWhatsappNumber && (
                <CustomTextField
                  placeholder="Type whatsapp number"
                  type="number"
                  value={state.whatsappNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setState((prev) => ({
                        ...prev,
                        whatsappNumber: e.target.value,
                      }));
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FilterCheckbox
                label="By Created Date"
                checked={checkboxes.bydate}
                onChange={(e) =>
                  setCheckboxes((prev) => ({ ...prev, bydate: !prev.bydate }))
                }
              />
              {checkboxes.bydate && (
                <Stack direction={"row"} gap={1}>
                  <Box flex={1}>
                    <DatePicker
                      value={state.createdFrom}
                      onChange={(date) =>
                        setState((prev: any) => ({
                          ...prev,
                          createdFrom: date,
                        }))
                      }
                      format="DD-MM-YYYY"
                    />
                  </Box>
                  <Box flex={1}>
                    <DatePicker
                      value={state.createdTo}
                      onChange={(date) =>
                        setState((prev: any) => ({ ...prev, createdTo: date }))
                      }
                      format="DD-MM-YYYY"
                    />
                  </Box>
                </Stack>
              )}
            </Grid>

            <Grid item xs={12}>
              <FilterCheckbox
                label="By Gender"
                checked={checkboxes.byGender}
                onChange={(e) =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    byGender: !prev.byGender,
                  }))
                }
              />
              {checkboxes.byGender && (
                <CustomSelectBox
                  value={state.gender}
                  onChange={(e) =>
                    setState((prev: any) => ({
                      ...prev,
                      gender: e.target.value,
                    }))
                  }
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </CustomSelectBox>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterCheckbox
                label="By State"
                checked={checkboxes.byState}
                onChange={(e) =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    byState: !prev.byState,
                  }))
                }
              />
              {checkboxes.byState && (
                <CustomSelectBox
                  native
                  value={state.state}
                  onChange={(e) =>
                    setState((prev: any) => ({
                      ...prev,
                      state: e.target.value,
                      district: "",
                    }))
                  }
                >
                  <MenuItem value="" component="option">
                    Select
                  </MenuItem>
                  {getStateListData?.data?.stateList?.map((res: string) => (
                    <MenuItem value={res} component="option">
                      {res}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterCheckbox
                label="By District"
                checked={checkboxes.byDistrict}
                onChange={(e) =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    byDistrict: !prev.byDistrict,
                  }))
                }
              />
              {checkboxes.byDistrict && (
                <CustomSelectBox
                  native
                  value={state.district}
                  onChange={(e) =>
                    setState((prev: any) => ({
                      ...prev,

                      district: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="" component="option">
                    Select
                  </MenuItem>
                  {getDistrictListData?.data?.districtList?.map(
                    (res: string) => (
                      <MenuItem value={res} component="option">
                        {res}
                      </MenuItem>
                    )
                  )}
                </CustomSelectBox>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterCheckbox
                label="By Designation"
                checked={checkboxes.byDesignation}
                onChange={(e) =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    byDesignation: !prev.byDesignation,
                  }))
                }
              />
              {checkboxes.byDesignation && (
                <CustomSelectBox
                  value={state.desgination}
                  onChange={(e) =>
                    setState((prev: any) => ({
                      ...prev,
                      desgination: e.target.value,
                    }))
                  }
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {getDesignationDropdownData?.data?.list?.map(
                    (res: string) => (
                      <MenuItem value={res}>{res}</MenuItem>
                    )
                  )}
                </CustomSelectBox>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </CommonFilterDialog>
    </>
  );
};

export default StaffFilter;
