import React from "react";
import Fallback from "../../common/Fallback";
const Photo = React.lazy(() => import("./Photo"));
const UploadEventPhoto = React.lazy(() => import("./UploadEventPhoto"));
const UploadGeneralPhoto = React.lazy(() => import("./UploadGeneralPhoto"));
const UploadBusinessPhoto = React.lazy(() => import("./UploadBusinessPhoto"));
const UploadPoliticalPhoto = React.lazy(() => import("./UploadPoliticalPhoto"));

export const PhotoPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <Photo {...props} />
  </React.Suspense>
);
export const UploadEventPhotoPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <UploadEventPhoto {...props} />
  </React.Suspense>
);
export const UploadGeneralPhotoPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <UploadGeneralPhoto {...props} />
  </React.Suspense>
);
export const UploadBusinessPhotoPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <UploadBusinessPhoto {...props} />
  </React.Suspense>
);
export const UploadPoliticalPhotoPage = (props: any) => (
  <React.Suspense fallback={<Fallback />}>
    <UploadPoliticalPhoto {...props} />
  </React.Suspense>
);
