import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";

import React from "react";
type Props = {
  open: boolean;
  onClose: () => void;
  handleDelete: () => void;
  loading: boolean;
  deleteMessage?: string;
};
const DeleteConfirmationDialog: React.FC<Props> = ({
  open = false,
  onClose,
  handleDelete,
  loading = false,
  deleteMessage = "",
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography
          sx={{
            fontSize: "1.1rem",
            fontWeight: 500,
            color: "text.primary",
            textTransform: "capitalize",
          }}
        >
          Are you sure you want to delete{" "}
          {deleteMessage && (
            <span style={{ fontStyle: "italic", fontWeight: 600 }}>
              "{deleteMessage}"
            </span>
          )}
        </Typography>
        <Stack direction={"row"} justifyContent={"center"} gap={2} mt={2}>
          <Button
            color="secondary"
            variant="outlined"
            size="medium"
            type="submit"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <LoadingButton
            color="error"
            loading={loading}
            variant="contained"
            size="medium"
            type="submit"
            onClick={handleDelete}
          >
            CONFIRM
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
