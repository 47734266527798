import { TextFieldProps } from "@mui/material";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { CustomTextField } from "./globalStyledComponent";

type ControllerInputProps<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  rules?: RegisterOptions<TFieldValues>;
  numberLenght?: number;
} & TextFieldProps;

const ControllerInput = <TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  numberLenght,
  ...rest
}: ControllerInputProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) =>
        numberLenght ? (
          <CustomTextField
            {...field}
            onChange={(e) => {
              if (e.target.value.length <= numberLenght) {
                field.onChange(e);
              }
            }}
            {...rest}
            variant="outlined"
            size="small"
            fullWidth
            error={!!error}
            helperText={error?.message || rest.helperText}
          />
        ) : (
          <CustomTextField
            {...field}
            {...rest}
            variant="outlined"
            size="small"
            fullWidth
            error={!!error}
            helperText={error?.message || rest.helperText}
          />
        )
      }
    />
  );
};

export default ControllerInput;
