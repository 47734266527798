import {
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
  Box,
  Stack,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation } from "react-query";
import formatDate from "../../common/Formater";
import CommonInputSmall from "../../common/CommonInputSmall";

import { deleteFrame, updateFrame } from "../../services/frame.service";

// Style for PopUp Model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  backgroundColor: "#fff",
  boxShadow: 24,
  borderRadius: "10px",
  width: "700.638px",
};

const CommonActionFrame = ({ event: data, refresh }) => {
  const event = data;
  const [open, setOpen] = useState({ view: false, edit: false, delete: false });

  const openModel = (name) => {
    if (name === "edit") {
      setOpen((prev) => ({ ...prev, edit: true }));
    } else if (name === "delete") {
      setOpen((prev) => ({ ...prev, delete: true }));
    } else {
      setOpen((prev) => ({ ...prev, view: true }));
    }
  };
  const closeMdedel = (name) => {
    if (name === "edit") {
      setOpen((prev) => ({ ...prev, edit: false }));
    } else if (name === "delete") {
      setOpen((prev) => ({ ...prev, delete: false }));
    } else {
      setOpen((prev) => ({ ...prev, view: false }));
    }
  };

  // Updaing Event Functionality Mutation
  const { mutate: UpdateFrame } = useMutation(updateFrame, {
    onSuccess: (data) => {
      refresh();
      console.log("updated event succssfully event", data);
      closeMdedel("edit");
    },
    onError: (error) => {
      console.log("error while updating event", error.response.data.message);
    },
  });

  //deleting the event mutation
  const { mutate: DeleteFrame } = useMutation(deleteFrame, {
    onSuccess: (data) => {
      refresh();
      console.log("delete event succssfully event", data);
      closeMdedel("edit");
    },
    onError: (error) => {
      console.log("error while delteing event", error.response.data.message);
    },
  });

  const initialValues = {
    frameDocId: event?._id,
    themeColor: event?.themeColor,
    width: event?.width,
    framePic: event?.framePic,
    height: event?.height,
    sizeFor: event?.sizeFor,
    tags: ["diwali", "rakshabandhan"],
  };

  //Updating Event Form
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: (value) => {
      let body = {
        ...value,
        height: event?.height.toString(),
      };
      console.log("The From is submitting", value);
      UpdateFrame(body);
    },
  });

  //console.log("initial value s", values);

  return (
    <Stack direction="row" spacing={0.5} sx={{ m: 0 }}>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("view")}>
        <img src="./action_view.png" alt="view" />
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("edit")}>
        <img src="./action_edit.png" alt="edit" />
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("delete")}>
        <img src="./action_delete.png" alt="delete" />
      </IconButton>

      {/* View model */}
      <Modal
        open={open.view}
        onClose={() => closeMdedel("view")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledWrapper1>
          <Box sx={style} component={"form"}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                px: 2,
                py: 1,
              }}
            >
              <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
                view Frame
              </Typography>
              <IconButton
                sx={{ p: 0, margin: 0 }}
                onClick={() => closeMdedel("view")}
              >
                <img alt="close" src="/close_filter.png" />
              </IconButton>
            </Stack>
            <Divider />
            <Stack direction={"column"} sx={{ px: 2, paddingBottom: "40px" }}>
              <Stack width="100%" direction={"row"} spacing={2}>
                <Box className="box-profile">
                  <Typography className="title">Theme Color</Typography>
                  <Typography className="value">{event?.themeColor}</Typography>
                </Box>
                <Box className="box-profile">
                  <Typography className="title">Frame Code</Typography>
                  <Typography className="value">{event?.frameCode}</Typography>
                </Box>
              </Stack>
              <Stack width="100%" direction={"row"} spacing={2}>
                <Box className="box-profile">
                  <Typography className="title">Size For</Typography>
                  <Typography className="value">{event?.sizeFor}</Typography>
                </Box>
                <Box className="box-profile">
                  <Typography className="title">Account Created On </Typography>
                  <Typography className="value">
                    {formatDate(event?.createdOn)}
                  </Typography>
                </Box>
              </Stack>
              {event?.framePic && (
                <Box className="box-profile">
                  <Typography className="title">Frame Pic</Typography>
                  <Stack direction={"row"} justifyContent={"center"}>
                    <Box sx={{ background: "#fff", boxShadow: 1 }} p={1}>
                      <img
                        src={event?.framePic}
                        alt="frame-pic"
                        style={{ width: "200px", height: "200px" }}
                      />
                    </Box>
                  </Stack>
                </Box>
              )}
            </Stack>
          </Box>
        </StyledWrapper1>
      </Modal>

      {/* edit model */}
      <Modal
        open={open.edit}
        onClose={() => closeMdedel("edit")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component={"form"} onSubmit={handleSubmit}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              py: 1,
            }}
          >
            <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
              Edit Frame
            </Typography>
            <IconButton
              sx={{ p: 0, margin: 0 }}
              onClick={() => closeMdedel("edit")}
            >
              <img alt="close" src="/close_filter.png" />
            </IconButton>
          </Stack>
          <Divider />
          <Box sx={{ p: 3 }}>
            <Stack
              className="model-upload-button"
              sx={{
                width: "123.091px",
                height: "122.035px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "20px",
                border: "1px solid rgba(21, 70, 58, 0.20)",
                background: "rgba(90, 212, 183, 0.10)",
              }}
            >
              <img
                wisth="72px"
                height="69px"
                src={values?.framePic}
                alt="upload"
              />
            </Stack>
            <Stack
              spacing={2}
              sx={{ py: 2 }}
              direction={"row"}
              justifyContent={""}
            >
              <Box width="50%">
                <CommonInputSmall
                  id="theme-color"
                  name="themeColor"
                  label="Theme Color"
                  type="text"
                  value={values.themeColor}
                  handeler={handleChange}
                />
              </Box>
              <Box width="25%">
                <CommonInputSmall
                  id="sixw-width"
                  name="width"
                  label="Size(W)"
                  type="text"
                  value={values.width}
                  handeler={handleChange}
                />
              </Box>
              <Box width="25%">
                <CommonInputSmall
                  id="size-height"
                  name="height"
                  label="Size(H)"
                  type="text"
                  value={values.height}
                  handeler={handleChange}
                />
              </Box>
            </Stack>
            <Stack
              spacing={2}
              sx={{ py: 2 }}
              direction={"row"}
              justifyContent={""}
            >
              <Box width="50%">
                <CommonInputSmall
                  id="size-for"
                  name="sizeFor"
                  label="Size For"
                  type="text"
                  value={values.sizeFor}
                  handeler={handleChange}
                />
              </Box>
              <Box width="50%">
                <CommonInputSmall
                  id="tag"
                  label="Tag"
                  type="text"
                  value={values.tags}
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent={"flex-end"}>
              <Button
                type="submit"
                sx={{
                  width: "177px",
                  p: 1,
                  borderRadius: "10px",
                  background:
                    " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                  flexShrink: 0,
                }}
                variant="contained"
                size="small"
              >
                Upload
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>

      {/* delte model */}
      <Modal
        open={open.delete}
        onClose={() => setOpen((prev) => ({ ...prev, delete: false }))}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300, p: 3, textAlign: "center" }}>
          <Stack sx={{ justifyContent: "center", alignItem: "center", p: 3 }}>
            <Box>
              <img width="69px" src="./delete-icon.png" alt="delete" />
            </Box>
          </Stack>
          <Typography
            variant="h6"
            className="confirm-title"
            sx={{
              color: "#020c0a",
              textAlign: "center",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            Are You Sure ?
          </Typography>
          <Typography
            varient="body1"
            className="confirm-description"
            sx={{
              color: "#020C0A",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              marginTop: "15px",
              opacity: "0.5",
            }}
          >
            You want to delete this Event
          </Typography>
          <Stack
            direction="row"
            justifyContent={"center"}
            sx={{ marginTop: "30px" }}
            spacing={2}
          >
            <Button
              className="confirm-button"
              type="submit"
              sx={{
                width: "95px",
                height: "50px",
                p: 1,
                marginTop: "30px",
                borderRadius: "10px",
                flexShrink: 0,
              }}
              variant="outlined"
              size="small"
              onClick={() => closeMdedel("delete")}
            >
              No
            </Button>
            <Button
              className="confirm-button"
              type="submit"
              sx={{
                width: "95px",
                height: "50px",
                p: 1,
                marginTop: "30px",
                borderRadius: "10px",
                background:
                  " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                flexShrink: 0,
              }}
              variant="contained"
              size="small"
              onClick={() => {
                DeleteFrame({ frameDocId: event?._id });
                closeMdedel("delete");
              }}
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};

export default CommonActionFrame;

const StyledWrapper1 = styled(Box)`
  .box-profile {
    margin-top: 15px;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.1);
    background: rgba(93, 215, 186, 0.05);

    padding: 10px;
  }
  .title {
    color: #555;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .value {
    margin-top: 5px;
    width: 184px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    color: #555;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;
