import React, { useMemo, useState } from "react";
// import MyMenu from "../common/MyMenu1";
import {
  Box,
  Button,
  Container,
  LinearProgress,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import CommonTable1 from "../../common/CommanTable";
import { useMutation, useQuery } from "react-query";
import formatDate from "../../common/Formater";

import ExportButtons from "../../common/ExportButtons";
import TableActionButtons from "../../common/TableActionButtons";
import { MRT_ColumnDef } from "material-react-table";

import { Add } from "@mui/icons-material";
import AddNewStaffDialog from "../../dialogs/staff/AddNewStaffDialog";
import StaffFilter from "../../dialogs/filters/StaffFilter";
import { GetStaffParamsProps, StaffListColumnProps } from "../../types/staff";
import DeleteConfirmationDialog from "../../dialogs/DeleteConfirmationDialog";
import { deleteStaff, getStaffList } from "../../services/staff.service";
import { FormatFullName, onError } from "../../utils/helpers";

const StaffList = () => {
  const [data, setData] = useState([]);
  const [state, setState] = useState<{
    addNewStaffDialog: boolean;
    editData: StaffListColumnProps | null;
    deleteId: string;
    deleteMessage: string;
    fetch: boolean;
    filteredData: GetStaffParamsProps | null;
  }>({
    addNewStaffDialog: false,
    editData: null,
    deleteId: "",
    deleteMessage: "",
    fetch: false,
    filteredData: null,
  });
  const handleCloseAddNewStaffDialog = () => {
    setState((prev) => ({
      ...prev,
      addNewStaffDialog: false,
      editData: null,
    }));
  };
  const handleDeleteDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteMessage: "",
      deleteId: "",
    }));
  };
  const handleOnClickFilter = (filteredData: GetStaffParamsProps) => {
    setState((prev) => ({ ...prev, fetch: !prev.fetch, filteredData }));
  };
  const handleOnclickExcel = () => {};
  const handleOnclickPdf = () => {};
  const handleDeleteStaff = () => {
    deleteStaffMutate(state.deleteId);
  };
  const { isLoading: deleteStaffLoading, mutate: deleteStaffMutate } =
    useMutation({
      mutationKey: ["deleteStaff"],
      mutationFn: deleteStaff,
      onSuccess: () => {
        handleDeleteDialog();
        getStaffListRefetch();
      },
      onError: onError,
    });
  // getting Staff List
  const {
    isLoading: getStaffListLoading,
    isFetching: getStaffListFetching,
    refetch: getStaffListRefetch,
  } = useQuery({
    queryKey: ["getStaffList", state.fetch],
    queryFn: () => getStaffList(state.filteredData ? state.filteredData : {}),
    onSuccess: (staffList) => {
      setData(staffList.data.list);
    },
    onError: onError,
  });
  const columns: MRT_ColumnDef<StaffListColumnProps>[] = useMemo(
    () => [
      {
        header: "Sr. No.",
        id: "rowIndex", // Unique ID is required for non-accessor columns
        Cell: ({ row }) => (
          <div>{row.index + 1 > 9 ? row.index + 1 : `0${row.index + 1}`}</div>
        ),
        maxSize: 50,
      },
      {
        accessorKey: "firstName",
        header: "Staff Name",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <div>
              <img
                alt="profile"
                src={
                  original.profilePic
                    ? original.profilePic
                    : "/image-placeholder.jpeg"
                }
                style={{
                  height: "40px",
                  width: "40px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </div>
            <Typography>
              {FormatFullName(
                original.firstName,
                original.middleName,
                original.lastName
              )}
            </Typography>
          </Stack>
        ),
      },
      {
        accessorKey: "gender",
        header: "Gender",
        maxSize: 50,
      },
      {
        accessorKey: "mobileNumber",
        header: "Mobile No.",
        maxSize: 80,
      },
      {
        accessorKey: "designation",
        header: "Desgingation",
        maxSize: 60,
      },
      {
        accessorKey: "createdOn",
        header: "Account Created",
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return <div>{formatDate(value)}</div>; // Ensure `formatDate` is imported and functional
        },
        maxSize: 60,
      },
      {
        header: "Action",
        maxSize: 100,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <TableActionButtons
            showDelete={
              String(original?.designation).toLowerCase() !== "admin"
                ? true
                : false
            }
            showView={false}
            onClickEdit={() =>
              setState((prev) => ({ ...prev, editData: original }))
            }
            onClickDelete={() =>
              setState((prev) => ({
                ...prev,
                deleteId: original._id,
                deleteMessage: `${FormatFullName(
                  original.firstName,
                  original.middleName,
                  original.lastName
                )}`,
              }))
            }
          />
        ), // Assuming this component is correctly defined
      },
    ],
    []
  );
  return (
    <>
      <DeleteConfirmationDialog
        open={Boolean(state.deleteId)}
        handleDelete={handleDeleteStaff}
        loading={deleteStaffLoading}
        onClose={handleDeleteDialog}
        deleteMessage={state.deleteMessage}
      />
      <AddNewStaffDialog
        open={state.addNewStaffDialog || Boolean(state.editData)}
        onClose={handleCloseAddNewStaffDialog}
        refetch={getStaffListRefetch}
        editData={state.editData}
      />
      <Container maxWidth="xl">
        <Wrapper>
          <Stack
            direction={"row"}
            alignItems={"center"}
            my={1}
            justifyContent={"space-between"}
            gap={2}
          >
            <StaffFilter onClickFilter={handleOnClickFilter} />

            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() =>
                    setState((prev) => ({ ...prev, addNewStaffDialog: true }))
                  }
                >
                  Add New
                </Button>
              </Box>
              <ExportButtons
                onClickExcel={handleOnclickExcel}
                onClickPdf={handleOnclickPdf}
              />
            </Stack>
          </Stack>

          {(getStaffListLoading || getStaffListFetching) && <LinearProgress />}
          <CommonTable1
            data={data}
            columns={columns}
            muiTableBodyRowProps={{ hover: true }}
            style={{ borderRadius: "10px" }}
          />
        </Wrapper>
      </Container>
    </>
  );
};
export default StaffList;
const Wrapper = styled(Box)``;
